var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from 'henra-store/services/http.service';
export const authenticationService = new (class AuthenticationService extends HttpService {
    constructor() {
        super(...arguments);
        this.sessionTokenKey = 'accesstoken';
        this.authorizationHeaderPrefix = 'Bearer';
    }
    clearSessionToken() {
        localStorage.removeItem(this.sessionTokenKey);
    }
    getSessionToken(raw = false) {
        const token = localStorage.getItem(this.sessionTokenKey);
        if (!token) {
            return null;
        }
        return raw ? token : `${this.authorizationHeaderPrefix} ${token}`;
    }
    saveSessionToken(token) {
        localStorage.setItem(this.sessionTokenKey, token);
    }
    login(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.postRequest('/auth/token', credentials);
        });
    }
})();
