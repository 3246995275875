var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// types
import { XHRState } from 'henra-store/services/XHRState';
import { action, observable } from 'mobx';
import { boundMethod } from 'utilities';
// service
import { productsService } from './products.service';
class ProductsStore {
    constructor() {
        this.products = null;
    }
    fetchProduct(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.productToEdit = null;
            this.state = XHRState.Pending;
            try {
                const response = yield productsService.fetchProduct(id);
                this.productToEdit = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchProducts(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield productsService.fetchProducts(params);
                this.products = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    updateProduct(body) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield productsService.updateProduct(body);
                this.productToEdit = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    createProduct(body) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield productsService.createProduct(body);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    deleteProduct(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield productsService.deleteProduct(id);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
    sortProducts(by, direction) {
        this.products = this.products.slice().sort(function (a, b) {
            if (a[by] < b[by])
                return -1 * direction;
            if (a[by] > b[by])
                return 1 * direction;
            return 0;
        });
    }
    parsePrice(value, asNumber = false, addEuroSign = true) {
        const wholeValuePrice = value / 100;
        const fixedPrice = wholeValuePrice.toFixed(2).replace('.', ',');
        if (asNumber)
            return wholeValuePrice;
        return `${addEuroSign ? '\u20AC' : ''}${fixedPrice}`;
    }
}
__decorate([
    observable
], ProductsStore.prototype, "state", void 0);
__decorate([
    observable
], ProductsStore.prototype, "products", void 0);
__decorate([
    observable
], ProductsStore.prototype, "productToEdit", void 0);
__decorate([
    action
], ProductsStore.prototype, "fetchProduct", null);
__decorate([
    action
], ProductsStore.prototype, "fetchProducts", null);
__decorate([
    action
], ProductsStore.prototype, "updateProduct", null);
__decorate([
    action
], ProductsStore.prototype, "createProduct", null);
__decorate([
    boundMethod,
    action
], ProductsStore.prototype, "sortProducts", null);
export const productsStore = new ProductsStore();
