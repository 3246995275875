var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
// components
import { ActivityContainer } from '../ActivityContainer';
import { Icon } from '../Icon';
// store
import { usersStore } from 'henra-store';
// styles
import { classNames } from './user.style';
// utils
import { boundMethod, getUserDisplayName } from 'utilities';
let UserElement = class UserElement extends React.Component {
    render() {
        const user = usersStore.me;
        return (React.createElement("div", { className: classNames.container, onClick: this.handleClick },
            React.createElement(ActivityContainer, { state: usersStore.state }, user && (React.createElement("div", { className: classNames.layout },
                React.createElement("div", { className: classNames.logoContainer }, user.image ? (React.createElement("img", { src: `${process.env.API_URL}${user.image.url}` })) : (React.createElement(Icon, { icon: "user-tie", className: classNames.icon }))),
                React.createElement("div", null, getUserDisplayName(user)))))));
    }
    handleClick() {
        this.props.history.push('/me');
    }
};
__decorate([
    boundMethod
], UserElement.prototype, "handleClick", null);
UserElement = __decorate([
    observer
], UserElement);
export { UserElement };
