var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DealersScreen_1;
import React from 'react';
import { observer } from 'mobx-react';
// components
import { DataManager } from '@ui-components/elements/DataManager';
import { Page } from '@ui-components/elements/Page';
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
// store
import { dealersStore } from 'henra-store';
let DealersScreen = DealersScreen_1 = class DealersScreen extends React.Component {
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: dealersStore.state },
                React.createElement(DataManager, Object.assign({}, this.props, { data: dealersStore.dealers, dataIdentifierKey: "_id", includeKeys: DealersScreen_1.includeKeys, keyLabels: DealersScreen_1.keyHeaders, subjectName: "dealer", subjectNamePlural: "dealers", url: "dealers", title: "Dealers", state: dealersStore.state, 
                    // onFilter={this.handleFilter}
                    // onSort={dealersStore.sortDealers}
                    fetchHandler: this.fetchDealers })))));
    }
    fetchDealers(params) {
        dealersStore.fetchDealers(params);
    }
};
DealersScreen.includeKeys = [
    'store',
    'phone',
    'email',
    'address',
    'zip',
    'city',
    'country',
    'lat',
    'lng',
];
DealersScreen.keyHeaders = [
    'dealer',
    'telefoon',
    'e-mailadres',
    'adres',
    'postcode',
    'plaats',
    'land',
    'lat',
    'lng',
];
DealersScreen = DealersScreen_1 = __decorate([
    observer
], DealersScreen);
export { DealersScreen };
