var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import React from 'react';
// components
import { Icon } from '@ui-components/elements/Icon';
// style
import { classNames } from './QuickSearchResult.style';
// utils
import { boundMethod } from 'utilities';
export class QuickSearchResult extends React.Component {
    render() {
        const { icon, text } = this.props;
        return (React.createElement("div", { className: classNames.container, onClick: this.handleClick },
            icon && (React.createElement(React.Fragment, null,
                React.createElement(Icon, { icon: icon, className: classNames.icon }),
                ' ')),
            ' ',
            text));
    }
    handleClick() {
        const callback = this.props.onPress;
        if (callback)
            callback();
    }
}
__decorate([
    boundMethod
], QuickSearchResult.prototype, "handleClick", null);
