import React from 'react';
// components
import { List } from '../List';
import { Icon } from '../Icon';
// style
import { classNames } from './select.style';
export class Select extends React.Component {
    render() {
        const selectHTMLAttributes = this.props.htmlAttributes || {};
        const containerClassnames = [
            classNames.container,
            selectHTMLAttributes.className,
        ]
            .join(' ')
            .trim();
        const { icon, label, options } = this.props;
        return (React.createElement("div", { className: containerClassnames },
            label && React.createElement("label", { className: classNames.label }, label),
            React.createElement("select", Object.assign({}, selectHTMLAttributes, { className: classNames.select }),
                React.createElement(List, { items: options, template: this.createOptionNode })),
            React.createElement(Icon, { icon: icon, className: classNames.icon })));
    }
    createOptionNode(option, index) {
        return (React.createElement("option", { key: option.key || index, value: option.value }, option.label));
    }
}
Select.defaultProps = {
    icon: 'caret-down',
    options: [],
};
