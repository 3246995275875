import { mixins, styleController } from 'style';
const { colors, borderRadii } = styleController.guide;
export const classNames = {
    container: styleController.create({
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundImage: `linear-gradient(45deg,${colors.henra} 35%,${colors.pinkGlamour} 150%)`,
    }),
    anchor: styleController.create({
        textAlign: 'center',
        margin: '10px 5px',
        display: 'block',
        textDecoration: 'none',
        fontSize: '0.85rem',
        color: '#707070',
    }),
    input: [
        styleController.create({
            $nest: {
                '& input': { width: '100%' },
            },
        }),
        mixins.row(1),
    ].join(' '),
    button: [
        styleController.create({
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            width: '100%',
        }),
        mixins.row(1),
    ].join(' '),
    formContainer: [
        styleController.create({
            background: 'white',
            borderRadius: borderRadii[1],
            width: '300px',
        }),
        mixins.column(2),
        mixins.row(0.5, true),
    ].join(' '),
};
