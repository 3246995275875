var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { XHRState } from 'henra-store/services/XHRState';
import { action, observable } from 'mobx';
import { OrderStatus } from '.';
import { ordersService } from './orders.service';
class OrdersStore {
    constructor() {
        this.orders = null;
        this.inquiries = null;
        this.orderToEdit = null;
    }
    statusToString(value) {
        switch (value) {
            case OrderStatus.CANCELED:
                return 'geannuleerd';
            case OrderStatus.COMPLETED:
                return 'voltooid';
            case OrderStatus.INQUIRY:
                return 'aanvraag';
            case OrderStatus.ORDER:
                return 'in behandeling';
            case OrderStatus.QUOTATION:
                return 'offerte';
        }
    }
    fetchOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.orderToEdit = null;
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.fetchOrder(id);
                this.orderToEdit = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchInquiries(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.fetchInquiries(params);
                this.inquiries = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    fetchOrders(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.fetchOrders(params);
                this.orders = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    static sortOrders(orders, by = 'createdAt', direction = -1) {
        return orders.sort(function (a, b) {
            if (a[by] < b[by])
                return -1 * direction;
            if (a[by] > b[by])
                return 1 * direction;
            return 0;
        });
    }
    getPDFURL(id) {
        return ordersService.getPDFURL(id);
    }
    updateOrder(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.updateOrder(data);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    deleteOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.deleteOrder(id);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    downloadOrderPDF(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield ordersService.fetchPDF(id);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
}
__decorate([
    observable
], OrdersStore.prototype, "state", void 0);
__decorate([
    observable
], OrdersStore.prototype, "orders", void 0);
__decorate([
    observable
], OrdersStore.prototype, "inquiries", void 0);
__decorate([
    observable
], OrdersStore.prototype, "orderToEdit", void 0);
__decorate([
    action
], OrdersStore.prototype, "fetchOrder", null);
__decorate([
    action
], OrdersStore.prototype, "fetchInquiries", null);
__decorate([
    action
], OrdersStore.prototype, "fetchOrders", null);
__decorate([
    action
], OrdersStore.prototype, "updateOrder", null);
__decorate([
    action
], OrdersStore.prototype, "deleteOrder", null);
export const ordersStore = new OrdersStore();
