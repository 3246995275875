export function getUserDisplayName(user) {
    let fullName = '';
    const fallback = 'Gebruiker';
    if (!user)
        return fallback;
    if (user.firstName)
        fullName += user.firstName;
    if (user.lastName)
        fullName += ` ${user.lastName}`;
    return (fullName.length > 0 ? fullName : user.email) || fallback;
}
