var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// types
import { XHRState } from 'henra-store/services/XHRState';
import { action, observable } from 'mobx';
// service
import { authenticationService } from './authentication.service';
class AuthenticationStore {
    constructor() {
        this.authenticated = authenticationService.getSessionToken()
            ? true
            : false;
    }
    login(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield authenticationService.login(credentials);
                authenticationService.saveSessionToken(response.token);
                this.state = XHRState.Fulfilled;
                this.authenticated = true;
                return response;
            }
            catch (error) {
                this.state = XHRState.Rejected;
                return Promise.reject(error);
            }
        });
    }
    logout() {
        this.authenticated = false;
        localStorage.clear();
        location.href = '/login';
    }
    getToken(raw = false) {
        return authenticationService.getSessionToken(raw);
    }
}
__decorate([
    observable
], AuthenticationStore.prototype, "state", void 0);
__decorate([
    observable
], AuthenticationStore.prototype, "authenticated", void 0);
__decorate([
    action
], AuthenticationStore.prototype, "login", null);
__decorate([
    action
], AuthenticationStore.prototype, "logout", null);
export const authenticationStore = new AuthenticationStore();
