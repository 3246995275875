var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var RequestResetPasswordScreen_1;
// components
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { boundMethod } from 'utilities';
import { usersStore } from '../../../henra-store/users';
import { ActivityContainer } from '../../elements/ActivityContainer';
import { Button } from '../../elements/Button';
import { Form } from '../../elements/Form';
import { Input } from '../../elements/Input';
import { classNames } from '../Login/login.style';
let RequestResetPasswordScreen = RequestResetPasswordScreen_1 = class RequestResetPasswordScreen extends React.Component {
    render() {
        return (React.createElement("div", { className: classNames.container },
            React.createElement("div", { className: classNames.formContainer },
                React.createElement(ActivityContainer, { state: usersStore.state },
                    React.createElement(Form, { onSubmit: this.handleFormSubmit },
                        React.createElement(Input, Object.assign({}, RequestResetPasswordScreen_1.emailInputProps)),
                        React.createElement(Button, Object.assign({}, RequestResetPasswordScreen_1.buttonProps), "Verstuur")),
                    React.createElement(Link, { className: classNames.anchor, to: "/Login" }, "Log in")))));
    }
    handleFormSubmit(event, data) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(data);
            try {
                if (event.currentTarget.reportValidity()) {
                    yield usersStore.requestPasswordReset(data.email);
                }
            }
            catch (_a) { }
        });
    }
};
RequestResetPasswordScreen.emailInputProps = {
    label: 'email',
    required: true,
    className: classNames.input,
    htmlAttributes: {
        type: 'email',
        name: 'email',
        required: true,
    },
};
RequestResetPasswordScreen.buttonProps = {
    htmlAttributes: { className: classNames.button, type: 'submit' },
};
__decorate([
    boundMethod
], RequestResetPasswordScreen.prototype, "handleFormSubmit", null);
RequestResetPasswordScreen = RequestResetPasswordScreen_1 = __decorate([
    observer
], RequestResetPasswordScreen);
export { RequestResetPasswordScreen };
