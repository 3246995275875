import { styleController, mixins } from 'style';
const { colors, fontWeights, spacing } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            borderRight: `2px solid ${colors.cityLights}`,
            height: '100vh',
            paddingRight: `${spacing.horizontal * 3}px !important`,
            width: '260px',
        }),
        mixins.row(2, true),
        mixins.column(),
    ].join(' '),
    menuItem: [
        styleController.create({
            $nest: {
                '&.active': { borderLeft: `4px solid ${colors.henra}` },
            },
        }),
        mixins.row(),
        mixins.column(2),
    ].join(' '),
    link: styleController.create({
        color: colors.text.toString(),
        textDecoration: 'none',
        fontWeight: fontWeights.body[0],
    }),
    title: [
        styleController.create({
            marginBottom: spacing.vertical,
            marginTop: `${spacing.vertical * 3}px !important`,
            $nest: {
                '&:first-child': { marginTop: '0 !important' },
            },
        }),
        mixins.column(),
    ].join(' '),
};
