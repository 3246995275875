import React from 'react';
// styles
import { classNames } from './title.style';
export const Title = props => {
    const titleClassNames = [
        props.className || '',
        classNames.title(props.size || 0),
    ]
        .join(' ')
        .trim();
    return React.createElement("div", { className: titleClassNames }, props.children);
};
