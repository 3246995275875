import { styleController } from 'style';
const { borderRadii, colors, fonts, fontWeights, spacing, } = styleController.guide;
const inputDefaultBorderColor = colors.cityLights;
const inputFocusBorderColor = colors.soothingBreeze;
const inputValidBorderColor = colors.henra;
const inputInvalidColor = colors.pinkGlamour;
export const classNames = {
    container: styleController.create({
    // margin: `${spacing.vertical * 2}px 0`
    }),
    label: styleController.create({
        display: 'block',
        marginBottom: `${spacing.vertical * 0.5}px`,
        fontSize: '0.85rem',
        fontFamily: fonts.header,
        fontWeight: fontWeights.header[1],
        paddingLeft: `${borderRadii[0]}px`,
    }),
    inputContainer: styleController.create({
        position: 'relative',
    }),
    icon: styleController.create({
        position: 'absolute',
        left: spacing.vertical,
        top: '50%',
        transform: 'translateY(-50%)',
        color: colors.americanRiver.toString(),
    }),
    input: styleController.create({
        '-webkit-appearance': 'none',
        border: `1px solid ${inputDefaultBorderColor}`,
        background: colors.cityLights.toString(),
        borderRadius: `${borderRadii[0]}px`,
        boxSizing: 'border-box',
        fontSize: '1rem',
        padding: `${spacing.base}px`,
        fontFamily: fonts.body,
        color: colors.henra.toString(),
        $nest: {
            '&:focus': {
                outline: 'none',
                border: `1px solid ${inputFocusBorderColor}`,
                color: colors.text.toString(),
            },
            '&.valid': {
                border: `1px solid ${inputValidBorderColor}`,
            },
            '&.invalid': {
                border: `1px solid ${inputInvalidColor}`,
            },
            '&.with-icon': {
                paddingLeft: spacing.horizontal * 4,
            },
        },
    }),
    description: styleController.create({
        marginTop: `${spacing.vertical * 0.5}px`,
        fontSize: '.8rem',
        $nest: {
            '&.warning': {
                color: inputInvalidColor.toString(),
            },
        },
    }),
};
