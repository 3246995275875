import { styleController, mixins } from 'style';
const { spacing, colors } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }),
        mixins.column(),
        mixins.row(1, true),
    ].join(' '),
    top: '',
    searchInput: styleController.create({
        $nest: {
            '& input': {
                width: '100%',
            },
        },
    }),
    advancedButton: styleController.create({
        marginTop: spacing.vertical,
    }),
    filter: [
        styleController.create({
            borderTop: `1px solid ${colors.henra}`,
            padding: `${spacing.vertical}px 0 0 0`,
        }),
        mixins.row(),
    ].join(' '),
    data: styleController.create({
        flex: 1,
        overflow: 'hidden',
    }),
    table: styleController.create({
        minWidth: '100%',
    }),
    buttonIcon: styleController.create({
        marginRight: spacing.horizontal,
    }),
};
