var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DataManager_1;
import { Button } from '@ui-components/elements/Button';
import { FilterForm } from '@ui-components/elements/FilterForm';
import { Form } from '@ui-components/elements/Form';
import { Icon } from '@ui-components/elements/Icon';
import { Table } from '@ui-components/elements/Table';
import { Title } from '@ui-components/elements/Title';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { boundMethod } from 'utilities';
import { encodeURLParams } from 'utilities/encodeURLParams';
import { Input } from '../Input';
import { classNames } from './dataManager.style';
let DataManager = DataManager_1 = class DataManager extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.tableRef = React.createRef();
        this.newButtonText = `${this.props.newSubjectWord || 'nieuwe'} ${this.props.subjectName}`;
        this.includeKeys = this.props.includeKeys;
        this.includeProcessors = this.props.includeProcessors;
        this.keyLabels = this.props.keyLabels;
        this.filterSelectOptions = this.includeKeys.map((value, index) => {
            const option = {
                value: value,
                label: this.keyLabels[index],
                key: index,
            };
            return option;
        });
        this.state = {
            showAdvancedFilters: false,
        };
    }
    render() {
        const useTableKey = this.props.dataIdentifierKey;
        const { create, data, state, defaultSortBy, title } = this.props;
        const { showAdvancedFilters } = this.state;
        const searchLabel = `Zoeken naar ${this.props.subjectNamePlural}`;
        const filterFormStyle = {
            display: showAdvancedFilters ? 'block' : 'none',
        };
        return (React.createElement("div", { className: classNames.container, ref: this.containerRef },
            React.createElement("div", { className: classNames.top },
                React.createElement(Title, null, title),
                create && (React.createElement(Button, { design: 1 /* Ghost */, onPress: this.handleCreateButtonPress },
                    React.createElement(Icon, { icon: "plus", className: classNames.buttonIcon }),
                    this.newButtonText)),
                React.createElement("div", { className: classNames.filter },
                    React.createElement(Form, { onSubmit: this.handleSearch },
                        React.createElement(Input, { label: searchLabel, showRequirement: false, htmlAttributes: DataManager_1.searchInputHTMLAttributes, className: classNames.searchInput, icon: "search" }))),
                React.createElement("div", { className: classNames.filter, style: filterFormStyle },
                    React.createElement(FilterForm, { selectOptions: this.filterSelectOptions, onSubmit: this.handleFilter, state: state }),
                    React.createElement(Button, { htmlAttributes: DataManager_1.hideFilterFormButtonHTMLAttributes, onPress: this.toggleAdvancedFilters, design: 1 /* Ghost */ }, "verbergen")),
                !showAdvancedFilters && (React.createElement("div", { className: classNames.filter },
                    React.createElement(Button, { onPress: this.toggleAdvancedFilters, design: 1 /* Ghost */ }, "geavanceerd zoeken")))),
            React.createElement(Table, { ref: this.tableRef, data: data, include: this.includeKeys, includeProcessors: this.includeProcessors, headers: this.keyLabels, useKey: useTableKey, className: classNames.table, rowHTMLAttributes: this.createItemHTMLAttributes, sort: this.handleSort, onPaginationChange: this.handlePaginationChange, defaultSortBy: defaultSortBy })));
    }
    componentDidMount() {
        this.handleFetch();
    }
    toggleAdvancedFilters() {
        this.setState({
            showAdvancedFilters: !this.state.showAdvancedFilters,
        });
    }
    handlePaginationChange(settings) {
        this.handleFetch(null, null, settings);
    }
    handleSort(value, order) {
        this.handleFetch(null, `${order < 0 ? '-' : ''}${value}`);
    }
    handleFilter(filters) {
        this.handleFetch(filters);
    }
    handleSearch(event) {
        const $target = event.target;
        const $input = $target[0];
        const value = $input.value;
        const scope = this.props.includeKeys.join(',');
        this.handleFetch(null, null, null, {
            search: value,
            searchScope: scope,
        });
    }
    handleFetch(filters, sort, pagination, search) {
        const tableRef = this.tableRef;
        const table = tableRef.current;
        if (!tableRef || !table)
            return;
        const params = Object.assign({ limit: pagination ? pagination.limit : table.state.limit, offset: pagination ? pagination.offset : table.state.offset, sort: sort ? sort : table.state.sortBy, pick: this.props.includeKeys.join(',') }, (search || {}));
        let URLParams = '?';
        URLParams += encodeURLParams(params);
        if (filters) {
            URLParams += '&';
            URLParams += encodeURLParams(filters, true);
        }
        this.props.fetchHandler(URLParams);
    }
    handleCreateButtonPress() {
        this.props.history.push(`${this.props.url}/edit`);
    }
    createItemHTMLAttributes(item) {
        return {
            onClick: this.props.edit ? this.handleItemClick.bind(this, item) : null,
        };
    }
    handleItemClick(item) {
        const id = this.props.dataIdentifierKey;
        const base = this.props.url;
        const url = `/${base}/edit/${item[id]}`;
        this.props.history.push(url, toJS(item));
    }
};
DataManager.defaultProps = {
    edit: true,
    create: true,
};
DataManager.searchInputHTMLAttributes = {
    type: 'text',
    placeholder: 'Waar ben je naar op zoek?',
};
DataManager.hideFilterFormButtonHTMLAttributes = { className: classNames.advancedButton };
__decorate([
    boundMethod
], DataManager.prototype, "toggleAdvancedFilters", null);
__decorate([
    boundMethod
], DataManager.prototype, "handlePaginationChange", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleSort", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleFilter", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleSearch", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleFetch", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleCreateButtonPress", null);
__decorate([
    boundMethod
], DataManager.prototype, "createItemHTMLAttributes", null);
__decorate([
    boundMethod
], DataManager.prototype, "handleItemClick", null);
DataManager = DataManager_1 = __decorate([
    observer
], DataManager);
export { DataManager };
