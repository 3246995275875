import { AssetsScreen } from '@ui-components/screens/Assets';
import { DealerScreen } from '@ui-components/screens/Dealer';
import { DealerCreationScreen } from '@ui-components/screens/DealerCreation';
import { DealersScreen } from '@ui-components/screens/Dealers';
import { InquiriesScreen } from '@ui-components/screens/Inquiries';
import { LoginScreen } from '@ui-components/screens/Login';
import { MeScreen } from '@ui-components/screens/Me';
import { OrderScreen } from '@ui-components/screens/Order';
import { OrdersScreen } from '@ui-components/screens/Orders';
import { ProductScreen } from '@ui-components/screens/Product';
import { ProductCreationScreen } from '@ui-components/screens/ProductCreation';
import { ProductsScreen } from '@ui-components/screens/Products';
import { UserScreen } from '@ui-components/screens/User';
import { UserCreationScreen } from '@ui-components/screens/UserCreation';
import { UsersScreen } from '@ui-components/screens/Users';
import { isDefined } from 'utilities';
import { RequestResetPasswordScreen } from './ui-components/screens/RequestResetPassword';
import { ResetPasswordScreen } from './ui-components/screens/ResetPassword';
function protectedRoute(role, availableToRoles, route) {
    if (availableToRoles.indexOf(role) > -1)
        return route;
    return null;
}
export function routes(role) {
    const routes = [
        {
            path: '/login',
            exact: true,
            component: LoginScreen,
        },
        {
            path: '/',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: AssetsScreen,
            menuInfo: {
                icon: 'folder',
                description: 'Informatie',
            },
        },
        {
            path: '/me',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: MeScreen,
        },
        {
            path: '/reset-password',
            exact: true,
            private: false,
            component: RequestResetPasswordScreen,
        },
        {
            path: '/reset-password/:email/:token',
            exact: true,
            private: false,
            component: ResetPasswordScreen,
        },
        protectedRoute(role, ['admin'], {
            path: '/dealers',
            private: true,
            exact: true,
            redirectTo: '/login',
            component: DealersScreen,
            menuInfo: {
                icon: 'user-tie',
                description: 'Dealers',
            },
        }),
        protectedRoute(role, ['admin'], {
            path: '/dealers/edit',
            private: true,
            exact: true,
            redirectTo: '/login',
            component: DealerCreationScreen,
        }),
        protectedRoute(role, ['admin'], {
            path: '/dealers/edit/:id',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: DealerScreen,
        }),
        protectedRoute(role, ['admin'], {
            path: '/users',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: UsersScreen,
            menuInfo: {
                icon: 'users',
                description: 'Gebruikers',
            },
        }),
        protectedRoute(role, ['admin'], {
            path: '/users/edit',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: UserCreationScreen,
        }),
        protectedRoute(role, ['admin'], {
            path: '/users/edit/:id',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: UserScreen,
        }),
        {
            path: '/orders',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: OrdersScreen,
            menuInfo: {
                icon: 'file-alt',
                description: 'Offertes',
            },
        },
        {
            path: '/orders/edit',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: UserCreationScreen,
        },
        {
            path: '/orders/edit/:id',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: OrderScreen,
        },
        {
            path: '/inquiries',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: InquiriesScreen,
            menuInfo: {
                icon: 'file',
                description: 'Aanvragen',
            },
        },
        {
            path: '/inquiries/edit/:id',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: OrderScreen,
        },
        {
            path: '/products',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: ProductsScreen,
            menuInfo: {
                icon: 'box-open',
                description: 'Producten',
            },
        },
        protectedRoute(role, ['admin'], {
            path: '/products/edit',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: ProductCreationScreen,
        }),
        protectedRoute(role, ['admin'], {
            path: '/products/edit/:id',
            exact: true,
            private: true,
            redirectTo: '/login',
            component: ProductScreen,
        }),
    ];
    return routes.filter(function (route) {
        return isDefined(route);
    });
}
