var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import serializeForm from 'form-serialize';
import React from 'react';
// utils
import { boundMethod } from 'utilities';
// style
import { classNames } from './Form.style';
export class Form extends React.Component {
    constructor() {
        super(...arguments);
        this.formRef = React.createRef();
        this.formContainerRef = React.createRef();
        this.serialize = serializeForm;
    }
    render() {
        const { children, htmlAttributes, submitOnEnter } = this.props;
        return (React.createElement("div", { ref: this.formContainerRef, className: classNames.formContainer },
            React.createElement("form", Object.assign({}, htmlAttributes, { onSubmit: this.handleSubmit, ref: this.formRef }),
                children,
                submitOnEnter && React.createElement("input", { type: "submit", hidden: true, tabIndex: -1 }))));
    }
    handleSubmit(event) {
        const { htmlAttributes, onSubmit } = this.props;
        const $activeElement = document.activeElement;
        const data = this.serialize(event.target, {
            hash: true,
        });
        event.preventDefault();
        if ($activeElement)
            $activeElement.blur();
        if (htmlAttributes.onSubmit)
            htmlAttributes.onSubmit(event);
        if (onSubmit)
            onSubmit(event, data);
    }
}
Form.defaultProps = {
    htmlAttributes: {},
};
__decorate([
    boundMethod
], Form.prototype, "handleSubmit", null);
