var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from 'henra-store/services/http.service';
import { authenticationStore } from 'henra-store/authentication';
export const ordersService = new (class OrdersService extends HttpService {
    fetchInquiries(urlParams) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/orders/inquiries' + (urlParams || ''));
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchOrders(urlParams) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/orders' + (urlParams || ''));
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/orders/' + id);
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    deleteOrder(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.deleteRequest('/orders/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    getPDFURL(id) {
        return `${HttpService.baseURL}orders/${id}/pdf?token=${authenticationStore.getToken(true)}`;
    }
    fetchPDF(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest(`orders/${id}/pdf`, {}, {
                    responseType: 'blob',
                });
            }
            catch (e) {
                console.error(e);
                return Promise.reject();
            }
        });
    }
    updateOrder(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.putRequest('/orders', data);
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
})();
