var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AssetsScreen_1;
import React from 'react';
import { observer } from 'mobx-react';
// components
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Form } from '@ui-components/elements/Form';
import { Icon } from '@ui-components/elements/Icon';
import { Input } from '@ui-components/elements/Input';
import { List } from '@ui-components/elements/List';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
import { Title } from '@ui-components/elements/Title';
// styles
import { classNames } from './assets.style';
// store
import { assetsStore, usersStore } from 'henra-store';
// utils
import { boundMethod, isDefined } from 'utilities';
import { mixins } from 'style';
let AssetsScreen = AssetsScreen_1 = class AssetsScreen extends React.Component {
    render() {
        const assets = (assetsStore.assets || []).filter(asset => !asset.isRemoved);
        const me = usersStore.me;
        const showForm = isDefined(me) ? me.role === 'admin' : false;
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: assetsStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", null,
                        React.createElement(Title, null, "Bestanden")),
                    React.createElement("div", { className: classNames.listContainer },
                        showForm && (React.createElement(Form, { htmlAttributes: { className: classNames.form }, onSubmit: this.handleFileSubmit, submitOnEnter: false },
                            React.createElement(Input, { label: "upload nieuw bestand", htmlAttributes: {
                                    type: 'file',
                                }, required: true }),
                            React.createElement(Button, { htmlAttributes: { type: 'submit' } }, "uploaden"))),
                        React.createElement(ScrollView, null,
                            React.createElement("div", { className: classNames.fileListContainer },
                                React.createElement(List, { items: assets, useKey: "_id", template: this.createFileNode }))))))));
    }
    componentDidMount() {
        assetsStore.fetchAssets();
    }
    handleFileSubmit(event) {
        const $target = event.target;
        const $fileInput = $target[0];
        const files = $fileInput.files;
        const formData = new FormData();
        if (files.length > 0) {
            for (let file of files) {
                formData.append('file', file, file.name);
            }
            assetsStore.uploadAsset(formData, true).then(assetsStore.fetchAssets);
        }
    }
    createPreviewNode(asset) {
        if (asset.type.indexOf('image') > -1) {
            return React.createElement("img", { src: `${process.env.API_URL}${asset.url}` });
        }
        if (asset.type.indexOf('video') > -1) {
            return React.createElement(Icon, { icon: "video" });
        }
        return React.createElement(Icon, { icon: "file" });
    }
    createFileNode(asset, index, useKey) {
        const downloadHandler = this.downloadFile.bind(this, asset);
        const deleteHandler = this.deleteFile.bind(this, asset);
        const fileClassNames = [classNames.fileName, 'file-name'].join(' ');
        const fileTypeLabel = `- ${asset.type.split('/')[0]}`;
        const key = asset[useKey];
        const previewNode = this.createPreviewNode(asset);
        return (React.createElement("div", { key: key, className: classNames.file },
            React.createElement("div", { className: classNames.preview }, previewNode),
            React.createElement("div", { className: classNames.info },
                React.createElement("div", { className: classNames.infoRow },
                    React.createElement("span", { className: fileClassNames }, asset.originalFilename),
                    React.createElement("span", { className: classNames.fileTypeLabel }, fileTypeLabel)),
                React.createElement("div", { className: classNames.infoRow }, asset.createdAt)),
            React.createElement("div", { className: mixins.column(2) },
                React.createElement(Button, { design: 1 /* Ghost */, onPress: downloadHandler, htmlAttributes: AssetsScreen_1.fileButtonHTMLAttributes },
                    React.createElement(Icon, { icon: "cloud-download-alt" })),
                React.createElement(Button, { design: 1 /* Ghost */, onPress: deleteHandler, htmlAttributes: AssetsScreen_1.fileButtonHTMLAttributes },
                    React.createElement(Icon, { icon: "trash" })))));
    }
    downloadFile(asset) {
        const $anchor = document.createElement('a');
        const $body = document.body;
        const src = `${process.env.API_URL}${asset.url}`;
        $anchor.href = src;
        $anchor.target = 'blank';
        $anchor.download = asset.originalFilename;
        $body.appendChild($anchor);
        $anchor.click();
        $body.removeChild($anchor);
        $anchor.remove();
    }
    deleteFile(asset) {
        const shouldDelete = confirm(`Weet u zeker dat u ${asset.originalFilename} wil verwijderen?`);
        if (shouldDelete)
            assetsStore.deleteAsset(asset._id);
    }
};
AssetsScreen.fileButtonHTMLAttributes = {
    className: classNames.fileButton,
};
__decorate([
    boundMethod
], AssetsScreen.prototype, "createFileNode", null);
AssetsScreen = AssetsScreen_1 = __decorate([
    observer
], AssetsScreen);
export { AssetsScreen };
