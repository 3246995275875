var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var UsersScreen_1;
import React from 'react';
import { observer } from 'mobx-react';
// components
import { DataManager } from '@ui-components/elements/DataManager';
import { Page } from '@ui-components/elements/Page';
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
// store
import { usersStore } from 'henra-store';
let UsersScreen = UsersScreen_1 = class UsersScreen extends React.Component {
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: usersStore.state },
                React.createElement(DataManager, Object.assign({}, this.props, { data: usersStore.users, dataIdentifierKey: "_id", includeKeys: UsersScreen_1.includeKeys, keyLabels: UsersScreen_1.keyHeaders, subjectName: "gebruiker", subjectNamePlural: "gebruikers", url: "users", title: "Gebruikers", state: usersStore.state, fetchHandler: this.fetchUsers })))));
    }
    fetchUsers(params) {
        usersStore.fetchUsers(params);
    }
};
UsersScreen.includeKeys = [
    'email',
    'firstName',
    'lastName',
    'dealer.store',
];
UsersScreen.keyHeaders = [
    'e-mailadres',
    'voornaam',
    'achternaam',
    'dealer',
];
UsersScreen = UsersScreen_1 = __decorate([
    observer
], UsersScreen);
export { UsersScreen };
