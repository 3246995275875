var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable } from 'mobx';
// types
import { XHRState } from 'henra-store/services/XHRState';
// service
import { assetsService } from './assets.service';
import { boundMethod } from 'utilities';
class AssetsStore {
    fetchAssets() {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield assetsService.fetchAssets();
                this.assets = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                Promise.reject(e);
            }
        });
    }
    uploadAsset(formData, isPublic = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield assetsService.uploadAsset(formData, isPublic);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
    deleteAsset(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield assetsService.deleteAsset(id);
                yield this.fetchAssets();
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
}
__decorate([
    observable
], AssetsStore.prototype, "state", void 0);
__decorate([
    observable
], AssetsStore.prototype, "assets", void 0);
__decorate([
    boundMethod,
    action
], AssetsStore.prototype, "fetchAssets", null);
__decorate([
    action
], AssetsStore.prototype, "uploadAsset", null);
__decorate([
    action
], AssetsStore.prototype, "deleteAsset", null);
export const assetsStore = new AssetsStore();
