import { styleController, mixins } from 'style';
const { colors, fontWeights, animationDurations, spacing, } = styleController.guide;
const borderWidth = '4px';
const borderColorTransition = `border-color ${animationDurations.effect}ms linear`;
const colorTransition = `color ${animationDurations.effect}ms linear`;
const iconColor = colors.pinkGlamour.lighten(0.2).toString();
const hoverIconColor = colors.pinkGlamour.toString();
const activeIconColor = colors.henra.toString();
const textColor = colors.text.lighten(2).toString();
const hoverTextColor = colors.text.toString();
export const classNames = {
    menuItem: [
        styleController.create({
            cursor: 'pointer',
            color: textColor,
            textDecoration: 'none',
            fontWeight: fontWeights.body[0],
            display: 'block',
            transition: [borderColorTransition, colorTransition].join(', '),
            $nest: {
                '&.active': {
                    $nest: { '& .icon': { color: activeIconColor } },
                },
                '&:hover': {
                    transitionDuration: '0ms',
                    color: hoverTextColor,
                    $nest: {
                        '& *': { transitionDuration: '0ms' },
                        '& .icon': { color: hoverIconColor },
                        '& .active': { color: activeIconColor },
                    },
                },
            },
        }),
        mixins.row(0.5, true),
    ].join(' '),
    link: styleController.create({
        display: 'flex',
    }),
    iconContainer: styleController.create({
        width: 32 + spacing.horizontal,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    icon: styleController.create({
        color: iconColor,
        transition: colorTransition,
    }),
};
