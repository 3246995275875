var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from 'henra-store/services/http.service';
export const usersService = new (class UsersService extends HttpService {
    fetchUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/users');
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    /**
     * Request a password reset for the provided email
     * @param email
     */
    requestPasswordReset(email) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.postRequest(`/users/reset-password/${encodeURIComponent(email)}`, null);
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    /**
     * reset a password
     * @param email
     */
    resetPassword(email, token, password) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.postRequest(`/users/reset-password/${encodeURIComponent(email)}/${encodeURIComponent(token)}`, {
                    password,
                });
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchUser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/users/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    deleteUser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.deleteRequest('/users/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    fetchMe() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/users/me');
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    updateUser(body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.putRequest(`/users`, body);
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    createUser(body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.postRequest('/users', body);
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
})();
