var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ProductScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Icon } from '@ui-components/elements/Icon';
// components
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
// store
import { productsStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
// utils
import { boundMethod, isDefined } from 'utilities';
// styles
import { classNames } from './product.style';
let ProductScreen = ProductScreen_1 = class ProductScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
        };
        const product = productsStore.productToEdit;
        const productId = props.match.params['id'];
        if (!product || product._id !== productId) {
            productsStore.fetchProduct(productId);
        }
    }
    render() {
        const product = isDefined(productsStore.productToEdit)
            ? this.parseProduct(productsStore.productToEdit)
            : null;
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: productsStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.handleBackButtonPress },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            "products")),
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" }, product ? (React.createElement(ModelEditor, { title: `Product beheren: ${product.name}`, data: product, state: productsStore.state, include: this.getIncludes(ProductScreen_1.includeProductKeys, ProductScreen_1.includeProductLabels), onSubmit: this.handleFormSubmit, onDelete: this.handleDelete })) : null))))));
    }
    parseProduct(product) {
        const fixedPrice = (product.price / 100).toFixed(2);
        return Object.assign(Object.assign({}, product), { price: fixedPrice });
    }
    handleFormSubmit(event, data) {
        const productId = this.props.match.params['id'];
        productsStore.updateProduct(Object.assign({ _id: productId }, Object.assign(Object.assign({}, data), { price: data.price * 100 })));
    }
    handleDelete(event, data) {
        const productId = data._id;
        const shouldDelete = confirm(`Weet u zeker dat u dit product wil verwijderen?`);
        if (shouldDelete) {
            productsStore.deleteProduct(productId).then(() => {
                this.props.history.push('/products');
            });
        }
    }
    handleBackButtonPress() {
        this.props.history.push('/products');
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            const props = {
                key: field,
                inputProps: {
                    label: labels[index],
                    htmlAttributes: {
                        type: field === 'price' ? 'number' : 'text',
                        step: '0.01',
                        disabled: field === 'productId',
                    },
                },
            };
            return props;
        });
    }
};
ProductScreen.includeProductKeys = [
    'name',
    'dimensions',
    'price',
    'description',
    'productId',
];
ProductScreen.includeProductLabels = [
    'productnaam (wagen)',
    'afmeting',
    'prijs',
    'beschrijving',
    'model',
];
__decorate([
    boundMethod
], ProductScreen.prototype, "handleFormSubmit", null);
__decorate([
    boundMethod
], ProductScreen.prototype, "handleDelete", null);
__decorate([
    boundMethod
], ProductScreen.prototype, "handleBackButtonPress", null);
ProductScreen = ProductScreen_1 = __decorate([
    observer
], ProductScreen);
export { ProductScreen };
