import { styleController } from 'style';
export const defaultBackgroundColor = 'transparent';
export const defaultIconColor = 'white';
export const defaultCircleColor = styleController.guide.colors.henra.toString();
export const defaultErrorColor = styleController.guide.colors.pinkGlamour.toString();
export const classNames = {
    container: styleController.create({
        position: 'relative',
    }),
    check: styleController.create({
        opacity: 0,
    }),
    cross: styleController.create({
        opacity: 0,
    }),
};
