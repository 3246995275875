var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DealerScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Icon } from '@ui-components/elements/Icon';
// components
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
// store
import { dealersStore, usersStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
// utils
import { boundMethod, filterFormInputs } from 'utilities';
// styles
import { classNames } from './dealer.style';
let DealerScreen = DealerScreen_1 = class DealerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
        };
        const dealer = dealersStore.dealerToEdit;
        const dealerId = props.match.params['id'];
        if (!dealer || dealer._id !== dealerId) {
            dealersStore.fetchDealer(dealerId);
        }
    }
    render() {
        const dealer = dealersStore.dealerToEdit || this.getEmptyDealer();
        return dealer ? (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: dealersStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.handleBackButtonPress },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            "dealers")),
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement(ModelEditor, { title: `Dealer beheren: ${dealer.store}`, data: dealer, state: dealersStore.state, include: this.getIncludes(DealerScreen_1.includeDealerKeys, DealerScreen_1.includeDealerLabels), onSubmit: this.handleFormSubmit, onDelete: usersStore.me.role === 'admin' && this.handleDelete }))))))) : null;
    }
    handleFormSubmit(event, data) {
        const dealerId = this.props.match.params['id'];
        const $form = event.target;
        const formData = new FormData();
        const $fileInput = filterFormInputs($form, { type: 'file' })[0];
        const files = $fileInput.files;
        for (let file of files) {
            formData.append('file', file, file.name);
        }
        dealersStore.updateDealer(Object.assign({ _id: dealerId }, data), files.length > 0 ? formData : null);
    }
    handleDelete(event, dealer) {
        const doDelete = confirm('Weet je het zeker?');
        if (doDelete) {
            dealersStore.removeDealer(dealer).then(() => {
                this.props.history.goBack();
            });
        }
    }
    getEmptyDealer() {
        let dealer = {};
        DealerScreen_1.includeDealerKeys.forEach(key => {
            dealer[key] = '';
        });
        return dealer;
    }
    handleBackButtonPress() {
        this.props.history.push('/dealers');
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            const props = {
                key: field,
                inputProps: {
                    label: labels[index],
                    htmlAttributes: {
                        type: field === 'image' ? 'file' : 'text',
                    },
                },
            };
            return props;
        });
    }
};
DealerScreen.includeDealerKeys = [
    'image',
    'store',
    'address',
    'zip',
    'city',
    'country',
    'email',
    'phone',
    'lat',
    'lng',
];
DealerScreen.includeDealerLabels = [
    'logo',
    'filiaal',
    'adres',
    'postcode',
    'plaats',
    'land',
    'e-mailadres',
    'telefoon',
    'breedtegraad',
    'lengtegraad',
];
__decorate([
    boundMethod
], DealerScreen.prototype, "handleFormSubmit", null);
__decorate([
    boundMethod
], DealerScreen.prototype, "handleDelete", null);
__decorate([
    boundMethod
], DealerScreen.prototype, "handleBackButtonPress", null);
DealerScreen = DealerScreen_1 = __decorate([
    observer
], DealerScreen);
export { DealerScreen };
