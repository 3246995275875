var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ActivityIndicator } from '@ui-components/elements/ActivityIndicator';
import anime from 'animejs';
import { XHRState } from 'henra-store/services/XHRState';
import React from 'react';
import { boundMethod } from 'utilities';
import { classNames } from './activityContainer.style';
export class ActivityContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.containerRef = React.createRef();
        this.state = {
            showActivityIndicator: false,
        };
    }
    static getDerivedStateFromProps(props, state) {
        if (props.state === XHRState.Pending) {
            return {
                showActivityIndicator: true,
            };
        }
        return state;
    }
    render() {
        const { state } = this.props;
        return (React.createElement("div", { className: classNames.container, style: {
                pointerEvents: state === XHRState.Pending ? 'none' : 'unset',
            } },
            React.createElement("div", { className: classNames.container, ref: this.containerRef }, this.props.children),
            React.createElement("div", { className: classNames.activityIndicator }, this.state.showActivityIndicator && (React.createElement(ActivityIndicator, { animate: !!state, state: state, onAnimationEnd: this.handleActivityIndicatorAnimationEnd })))));
    }
    componentDidMount() {
        this.playStateChangeAnimation();
    }
    componentDidUpdate(prevProps, prevState) {
        this.playStateChangeAnimation();
    }
    playStateChangeAnimation() {
        const { state } = this.props;
        const $element = this.containerRef.current;
        if (state === XHRState.Pending && !this.animation) {
            anime.set($element, { pointerEvents: 'none' });
            this.animation = anime({
                targets: $element,
                easing: 'easeOutBack',
                duration: ActivityContainer.animationDuration,
                opacity: 0.5,
            });
        }
    }
    handleActivityIndicatorAnimationEnd() {
        const $element = this.containerRef.current;
        anime.set($element, { pointerEvents: null });
        anime({
            targets: $element,
            easing: 'easeOutCubic',
            duration: ActivityContainer.animationDuration,
            opacity: 1,
            scale: 1,
            pointerEvents: null,
            complete: () => {
                this.animation = null;
                const { onAnimationEnd } = this.props;
                this.setState({
                    showActivityIndicator: false,
                }, () => {
                    if (onAnimationEnd)
                        onAnimationEnd();
                });
            },
        });
    }
}
ActivityContainer.animationDuration = 300;
__decorate([
    boundMethod
], ActivityContainer.prototype, "handleActivityIndicatorAnimationEnd", null);
