import { styleController, mixins } from 'style';
export const classNames = {
    container: [
        styleController.create({
            width: '100%',
            maxWidth: '800px',
            margin: '0 auto',
            maxHeight: '100vh',
            overflow: 'hidden',
        }),
        mixins.column(),
    ].join(' '),
};
