var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var OrderScreen_1;
import React from 'react';
import { observer } from 'mobx-react';
import get from 'lodash-es/get';
// components
import { Button } from '@ui-components/elements/Button';
import { Form } from '@ui-components/elements/Form';
import { Icon } from '@ui-components/elements/Icon';
import { Input } from '@ui-components/elements/Input';
import { List } from '@ui-components/elements/List';
import { Page } from '@ui-components/elements/Page';
import { ProductView } from '@ui-components/elements/Product';
import { ScrollView } from '@ui-components/elements/ScrollView';
import { Title } from '@ui-components/elements/Title';
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
// stores
import { ordersStore, dealersStore } from 'henra-store';
// styles
import { classNames } from './order.style';
// utils
import { boundMethod, isDefined } from 'utilities';
import { SearchInput } from '@ui-components/elements/SearchInput';
import { OrderStatus } from 'henra-store/orders';
import { Select } from '@ui-components/elements/Select';
let OrderScreen = OrderScreen_1 = class OrderScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCosts: 0,
            discount: 0,
            exchangeConsumerDiscount: 0,
            exchangeDiscount: 0,
            modificationsPrice: 0,
            taxRate: 0,
            searchResults: [],
            selectedDealer: null,
            showAdvancedOptions: false,
        };
        this.isInquiry = location.pathname.indexOf('inquiries') > -1;
        this.searchInputRef = React.createRef();
        const order = ordersStore.orderToEdit;
        const orderId = props.match.params['id'];
        if (!order || order._id !== orderId) {
            ordersStore.fetchOrder(orderId).then(order => {
                this.setState({
                    deliveryCosts: order.deliveryCosts || 0,
                    discount: order.discount || 0,
                    exchangeConsumerDiscount: order.exchangeConsumerDiscount || 0,
                    exchangeDiscount: order.exchangeDiscount || 0,
                    modificationsPrice: order.modificationsPrice || 0,
                    taxRate: order.taxRate || 0,
                });
            });
        }
    }
    render() {
        const order = ordersStore.orderToEdit;
        const { searchResults, showAdvancedOptions } = this.state;
        if (!order)
            return null;
        // input nodes
        const businessInputs = this.getIncludes(0, 6);
        const consumerInputs = this.getIncludes(6, 9);
        const noteInput = this.getIncludes(9, 10);
        const clientInputs = this.getIncludes(10, 17);
        // prices
        const orderPriceBase = this.calculatePrice(order);
        const orderPriceAltered = this.calculatePrice(order, 'modificationsPrice', 'discount', 'deliveryCosts', 'exchangeDiscount');
        const orderPriceTotal = this.calculatePrice(order, 'modificationsPrice', 'discount', 'deliveryCosts', 'exchangeDiscount', 'exchangeConsumerDiscount', 'taxRate');
        // price tables
        const allOrderProducts = [
            order.modelProduct,
            ...order.extraProducts,
        ].filter(product => isDefined(product));
        const productsTableData = allOrderProducts.map(product => [
            `${product.name} - ${product.description}`,
            this.parseCurrency(product.price),
        ]);
        const priceTableDataBase = [
            ['model', 'prijs (excl btw)'],
            ...productsTableData,
            ['totaal', orderPriceBase[0]],
        ];
        const priceTableDataTotalExcTax = [['totaal', orderPriceAltered[0]]];
        const priceTableDataTotalIncTax = [
            ['totaal inclusief BTW', orderPriceTotal[0]],
        ];
        const statusOptions = this.getStatusOptions();
        const backButtonText = `terug naar ${this.isInquiry ? 'aanvragen' : 'offertes'}`;
        const statusSelectHTMLAttributes = {
            defaultValue: (order.status || 0).toString(),
            name: this.createInputName('status'),
        };
        const advancedButtonText = `geavanceerde opties ${showAdvancedOptions ? 'verbergen' : 'weergeven'}`;
        const deleteButtonText = this.isInquiry
            ? 'aanvraag verwijderen'
            : 'offerte verwijderen';
        const searchInputValue = get(this.state, ['selectedDealer', 'store']);
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: ordersStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.handleBackButtonPress },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            backButtonText),
                        order && order._id && (React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { className: classNames.downloadButton }, onPress: this.downloadPDF },
                            React.createElement(Icon, { icon: "download", className: classNames.buttonIcon }),
                            "download als pdf"))),
                    React.createElement("div", { className: classNames.scrollContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement("div", { className: classNames.formContainer },
                                React.createElement(Form, { onSubmit: this.handleFormSubmit },
                                    React.createElement("div", { className: classNames.formFragment },
                                        React.createElement(ProductView, { product: order.modelProduct })),
                                    React.createElement(Button, { htmlAttributes: { type: 'submit' } }, "Opslaan"),
                                    this.createPriceTableNode(priceTableDataBase),
                                    this.createFormFragmentNode(businessInputs),
                                    this.createPriceTableNode(priceTableDataTotalExcTax),
                                    this.createFormFragmentNode(consumerInputs),
                                    this.createPriceTableNode(priceTableDataTotalIncTax),
                                    this.createFormFragmentNode(noteInput),
                                    React.createElement(Title, null, "Klantgegevens"),
                                    this.createFormFragmentNode(clientInputs),
                                    React.createElement(Title, null, "Offertestatus"),
                                    React.createElement("div", { className: classNames.formFragment },
                                        React.createElement(Select, { options: statusOptions, htmlAttributes: statusSelectHTMLAttributes })),
                                    React.createElement(Title, null, "Dealerinformatie"),
                                    React.createElement("div", { className: classNames.formFragment },
                                        React.createElement(SearchInput, { ref: this.searchInputRef, onSubmit: this.searchDealers, data: searchResults, useAsKey: '_id', useResultKey: 'store', useResultIcon: 'user-tie', onResultPress: this.handleResultPress, inputProps: {
                                                label: this.isInquiry
                                                    ? 'Wijs een dealer toe'
                                                    : 'Wijzig dealer',
                                            }, value: searchInputValue })),
                                    !this.isInquiry &&
                                        this.createDealerInfoTableNode(this.state.selectedDealer || order.dealer),
                                    React.createElement("div", { className: classNames.advanced },
                                        React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { type: 'button' }, onPress: this.toggleAdvancedOptions }, advancedButtonText),
                                        showAdvancedOptions && (React.createElement("div", { className: classNames.advanced },
                                            React.createElement(Button, { htmlAttributes: { type: 'button' }, onPress: this.handleDelete }, deleteButtonText)))),
                                    React.createElement(Button, { htmlAttributes: { type: 'submit' } }, "Opslaan")))))))));
    }
    getStatusOptions() {
        const options = [];
        Object.keys(OrderStatus).forEach(function (key) {
            // isNaN omdat key dan 1 of "1" mag zijn (string representatie van number)
            const isNumber = !isNaN(Number(OrderStatus[key]));
            if (isNumber) {
                const value = OrderStatus[key];
                const label = ordersStore.statusToString(value);
                options.push({ key, value, label });
            }
        });
        return options;
    }
    toggleAdvancedOptions() {
        this.setState(state => ({
            showAdvancedOptions: !state.showAdvancedOptions,
        }));
    }
    handleDelete() {
        const orderID = this.props.match.params['id'];
        const shouldDelete = confirm(`Weet u zeker dat u deze ${this.isInquiry ? 'aanvraag' : 'offerte'} wil verwijderen?`);
        if (shouldDelete) {
            ordersStore.deleteOrder(orderID).then(() => {
                this.props.history.push(this.isInquiry ? '/inquiries' : '/orders');
            });
        }
    }
    createDealerInfoTableNode(dealer) {
        const rows = [
            ['Filiaal', get(dealer, 'store') || '-'],
            ['Telefoonnummer', get(dealer, 'phone') || '-'],
            ['E-mailadres', get(dealer, 'email') || '-'],
            ['Adres', get(dealer, 'address') || '-'],
            ['Postcode', get(dealer, 'zip') || '-'],
            ['Plaats', get(dealer, 'city') || '-'],
            ['Land', get(dealer, 'country') || '-'],
        ];
        return (React.createElement("table", { className: classNames.formFragment },
            React.createElement("tbody", null,
                React.createElement(List, { items: rows, template: (row, index) => (React.createElement("tr", { key: index },
                        React.createElement("td", null, row[0]),
                        React.createElement("td", null, row[1]))) }))));
    }
    searchDealers(value) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const results = yield dealersStore.searchDealers(value, false);
                this.setState({
                    searchResults: results,
                });
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    handleResultPress(item) {
        this.searchInputRef.current.inputRef.current.inputRef.current.value =
            item.store;
        this.setState({
            selectedDealer: item,
        });
    }
    createFormFragmentNode(includes) {
        return (React.createElement("div", { className: classNames.formFragment },
            React.createElement(List, { items: includes, template: this.createInputNode })));
    }
    createPriceTableRowNode(rowIndex, ...values) {
        return (React.createElement("div", { key: rowIndex, className: classNames.tr },
            React.createElement(List, { items: values, template: (value, index) => (React.createElement("div", { key: index, className: classNames.tc }, value)) })));
    }
    createPriceTableNode(rows) {
        return (React.createElement("div", { className: classNames.infoTable },
            React.createElement(List, { items: rows, template: (row, index) => this.createPriceTableRowNode(index, ...row) })));
    }
    createInputNode(item) {
        return (React.createElement(Input, Object.assign({ className: classNames.input }, item.inputProps, { key: item.key })));
    }
    parseCurrency(amount) {
        return (amount / 100).toFixed(2);
    }
    convertCurrency(amount) {
        return amount * 100;
    }
    calculatePrice(order, ...alterings) {
        const sumReducer = (total, value) => total + value;
        // concatenate prices of main product and extra's
        const prices = [
            get(order, ['modelProduct', 'price']) || 0,
            ...order.extraProducts.map(product => product.price),
        ];
        // calculate tax rate from percentage
        const taxRate = (this.state.taxRate || 0) / 100;
        // calculate subtotal from prices
        let total = prices.reduce(sumReducer);
        // calculate amount of tax
        let taxAmount = total * taxRate;
        // start discount percentage at zero
        let discountPercentage = 0;
        // for each price altering
        alterings.forEach(key => {
            switch (key) {
                // if altering is a discount, increase discount percentage
                case 'discount':
                    discountPercentage += this.state[key] / 100;
                    break;
                // if altering is tax, add it to total price
                case 'taxRate':
                    total += taxAmount;
                    break;
                // else, alter the total price by given absolute number input
                case 'deliveryCosts':
                case 'exchangeConsumerDiscount':
                case 'exchangeDiscount':
                case 'modificationsPrice':
                    total += this.state[key] * 100;
            }
        });
        // subtract discount from total price
        total -= total * discountPercentage;
        // return total and amount of tax
        return [this.parseCurrency(total), this.parseCurrency(taxAmount)];
    }
    handleBackButtonPress() {
        this.props.history.push(this.isInquiry ? '/inquiries' : '/orders');
    }
    createInputName(name) {
        const orderId = this.props.match.params['id'];
        return `${name}${OrderScreen_1.inputNameSplitter}${orderId}`;
    }
    parseInputName(name) {
        return name.split(OrderScreen_1.inputNameSplitter)[0];
    }
    getIncludes(start, end) {
        const fields = OrderScreen_1.includeOrderKeys.slice(start, end);
        const labels = OrderScreen_1.includeOrderLabels.slice(start, end);
        return fields.map((field, index) => {
            const fieldValue = ordersStore.orderToEdit[field];
            const defaultValue = fieldValue ? fieldValue.toString() : '';
            const inputType = this.getInputType(field);
            const include = {
                key: field,
                inputProps: {
                    label: labels[index],
                    htmlAttributes: {
                        required: field === 'taxRate',
                        placeholder: this.getInputPlaceholder(field),
                        type: inputType,
                        step: inputType === 'number' ? '0.01' : undefined,
                        onChange: this.handleInputChange.bind(this, field),
                        defaultValue: this.isCurrency(field)
                            ? this.parseCurrency(parseInt(defaultValue))
                            : defaultValue,
                        name: this.createInputName(field),
                    },
                },
            };
            return include;
        });
    }
    handleInputChange(field, event) {
        const value = event.target.value;
        const valueFloat = value ? parseFloat(value) : 0;
        let newState;
        switch (field) {
            case 'discount':
                newState = { discount: valueFloat };
                break;
            case 'exchangeConsumerDiscount':
                newState = { exchangeConsumerDiscount: valueFloat };
                break;
            case 'exchangeDiscount':
                newState = { exchangeDiscount: valueFloat };
                break;
            case 'deliveryCosts':
                newState = { deliveryCosts: valueFloat };
                break;
            case 'taxRate':
                newState = { taxRate: valueFloat };
                break;
            case 'modificationsPrice':
                newState = { modificationsPrice: valueFloat };
        }
        this.setState(newState);
    }
    getInputPlaceholder(field) {
        const percentageDiscount = 'korting in %';
        const absoluteDiscount = 'absolute waarde';
        switch (field) {
            case 'discount':
                return percentageDiscount;
            case 'exchangeConsumerDiscount':
                return absoluteDiscount;
            case 'exchangeDiscount':
                return absoluteDiscount;
            default:
                return '';
        }
    }
    isCurrency(key) {
        return (key === 'deliveryCosts' ||
            key === 'exchangeConsumerDiscount' ||
            key === 'exchangeDiscount' ||
            key === 'modificationsPrice');
    }
    isPercentage(key) {
        return key === 'discount' || key === 'taxRate';
    }
    getInputType(field) {
        return this.isPercentage(field) || this.isCurrency(field)
            ? 'number'
            : field === 'clientEmail'
                ? 'email'
                : 'text';
    }
    handleFormSubmit(event, data) {
        const orderId = this.props.match.params['id'];
        const formData = {};
        Object.keys(data).forEach((key, index) => {
            const _key = this.parseInputName(key);
            const value = this.isCurrency(_key)
                ? this.convertCurrency(data[key])
                : data[key];
            this.parseCurrency;
            formData[_key] = value;
        });
        const body = Object.assign(Object.assign({ _id: orderId }, formData), { dealer: get(this.state, ['selectedDealer', '_id']), search: null });
        ordersStore.updateOrder(body).then(response => {
            if (this.isInquiry) {
                this.props.history.push(`/orders/edit/${response._id}`);
            }
        });
    }
    downloadPDF() {
        return __awaiter(this, void 0, void 0, function* () {
            const order = ordersStore.orderToEdit;
            const orderId = order._id;
            try {
                const response = yield ordersStore.downloadOrderPDF(orderId);
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const $a = document.createElement('a');
                $a.href = url;
                $a.download = this.createFileName(order);
                $a.click();
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    formatFileNameFragment(fragment) {
        return fragment.toLowerCase().replace(/ /g, '');
    }
    createFileName(order) {
        const fileName = [
            'offerte',
            'henra',
            this.formatFileNameFragment(get(order, ['modelProduct', 'name']) || ''),
            this.formatFileNameFragment(get(order, ['author', 'dealer', 'store']) || ''),
        ]
            .filter(f => f)
            .join('-');
        return fileName;
    }
};
OrderScreen.inputNameSplitter = '__';
OrderScreen.includeOrderKeys = [
    'modifications',
    'modificationsPrice',
    'discount',
    'deliveryCosts',
    'exchange',
    'exchangeDiscount',
    'taxRate',
    'exchangeConsumer',
    'exchangeConsumerDiscount',
    'notes',
    'clientName',
    'clientAddress',
    'clientZip',
    'clientCity',
    'clientCountry',
    'clientPhone',
    'clientEmail',
];
OrderScreen.includeOrderLabels = [
    'opmerkingen (tekst)',
    'opmerkingen kosten (bedrag)',
    'korting (percentage)',
    'aflevering/keuringskosten (bedrag)',
    'inruil zakelijk (tekst)',
    'inruil zakelijk korting (bedrag)',
    'BTW (percentage)',
    'inruil consument (tekst)',
    'inruil consument korting (bedrag)',
    'opmerkingen/afspraken',
    'naam',
    'adres',
    'postcode',
    'plaats',
    'land',
    'telefoonnummer',
    'e-mailadres',
];
__decorate([
    boundMethod
], OrderScreen.prototype, "toggleAdvancedOptions", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "handleDelete", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "searchDealers", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "handleResultPress", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "calculatePrice", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "handleBackButtonPress", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "handleFormSubmit", null);
__decorate([
    boundMethod
], OrderScreen.prototype, "downloadPDF", null);
OrderScreen = OrderScreen_1 = __decorate([
    observer
], OrderScreen);
export { OrderScreen };
