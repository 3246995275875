import { styleController } from 'style';
const { spacing } = styleController.guide;
const maxInputWidth = '100%';
export const classNames = {
    container: styleController.create({
        position: 'relative',
    }),
    searchInput: styleController.create({
        $nest: {
            '& > input': {
                display: 'block',
                width: '100%',
                maxWidth: maxInputWidth,
                paddingLeft: spacing.horizontal * 3,
            },
        },
    }),
    results: styleController.create({
        position: 'absolute',
        width: '100%',
        maxWidth: maxInputWidth,
        borderRadius: styleController.guide.borderRadii[0],
        overflow: 'hidden',
        boxShadow: styleController.createBoxShadow(),
        zIndex: 999,
    }),
};
