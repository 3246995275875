var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import React from 'react';
import anime from 'animejs';
// components
import { Icon } from '@ui-components/elements/Icon';
// style
import { classNames, defaultBackgroundColor, defaultErrorColor, defaultIconColor, defaultCircleColor, } from './activityIndicator.style';
// utils
import { boundMethod, isDefined } from 'utilities';
import { XHRState } from 'henra-store/services/XHRState';
export class ActivityIndicator extends React.Component {
    constructor() {
        super(...arguments);
        this.svgContainerRef = React.createRef();
        this.svgCircleRef = React.createRef();
        this.svgCheckIconRef = React.createRef();
        this.svgCrossIconRef = React.createRef();
    }
    render() {
        const { backgroundColor, circleColor, iconColor, state, width, height, } = this.props;
        const checkIconPath = this.createCheckIconPath();
        const containerClassNames = this.createClassNames();
        const clipURL = `url(${location.href}#clip)`;
        return (React.createElement(React.Fragment, null,
            React.createElement("svg", { viewBox: ActivityIndicator.viewbox, width: width, height: height, className: containerClassNames, ref: this.svgContainerRef },
                React.createElement("defs", null,
                    React.createElement("circle", { cx: ActivityIndicator.shapeCenter, cy: ActivityIndicator.shapeCenter, id: "circle", r: ActivityIndicator.backgroundCircleRadius }),
                    React.createElement("clipPath", { id: "clip" },
                        React.createElement("use", { xlinkHref: "#circle" }))),
                React.createElement("use", { xlinkHref: "#circle", fill: backgroundColor }),
                React.createElement("circle", { clipPath: clipURL, cx: ActivityIndicator.shapeCenter, cy: ActivityIndicator.shapeCenter, fill: "none", r: ActivityIndicator.circleRadius, ref: this.svgCircleRef, stroke: circleColor, strokeLinecap: "round", strokeWidth: ActivityIndicator.strokeWidth }),
                state === XHRState.Fulfilled && (React.createElement("path", { className: classNames.cross, d: checkIconPath, fill: "none", ref: this.svgCheckIconRef, stroke: iconColor, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: ActivityIndicator.strokeWidth })),
                state === XHRState.Rejected && (React.createElement("g", { ref: this.svgCrossIconRef },
                    React.createElement(Icon, { icon: "times", transform: {
                            size: 5,
                        }, color: defaultErrorColor, className: classNames.cross }))))));
    }
    componentDidMount() {
        if (this.props.animate)
            this.startAnimation();
    }
    componentDidUpdate() {
        if (this.props.animate)
            this.startAnimation();
    }
    componentWillUnmount() {
        const $targets = [
            this.svgCircleRef.current,
            this.svgCrossIconRef.current,
            this.svgCheckIconRef.current,
            this.svgContainerRef.current,
        ];
        anime.remove($targets);
    }
    createClassNames() {
        const classNameProp = this.props.className;
        return [classNames.container, classNameProp].join(' ').trim();
    }
    createCheckIconPath() {
        const sizeUnit = ActivityIndicator.viewboxSize * 0.2;
        const centerPoint = [
            ActivityIndicator.viewboxSize / 2,
            ActivityIndicator.viewboxSize / 2 - ActivityIndicator.viewboxSize * 0.05,
        ];
        const angle = (45 * Math.PI) / 180.0;
        function rotatePoint(x, y) {
            return [
                Math.cos(angle) * (x - centerPoint[0]) -
                    Math.sin(angle) * (y - centerPoint[1]) +
                    centerPoint[0],
                Math.sin(angle) * (x - centerPoint[0]) +
                    Math.cos(angle) * (y - centerPoint[1]) +
                    centerPoint[1],
            ];
        }
        const points = [
            rotatePoint(centerPoint[0] - sizeUnit / 2, centerPoint[1] + sizeUnit),
            rotatePoint(centerPoint[0] + sizeUnit / 2, centerPoint[1] + sizeUnit),
            rotatePoint(centerPoint[0] + sizeUnit / 2, centerPoint[1] - sizeUnit),
        ];
        const pathTemplate = [
            {
                action: 'M',
                x: points[0][0],
                y: points[0][1],
            },
            {
                action: 'L',
                x: points[1][0],
                y: points[1][1],
            },
            {
                action: 'L',
                x: points[2][0],
                y: points[2][1],
            },
        ];
        const instructions = pathTemplate.map((point, index) => {
            return `${point.action}${point.x} ${point.y}`.trim();
        });
        return instructions.join(' ');
    }
    startAnimation() {
        const $circle = this.svgCircleRef.current;
        const radius = parseInt($circle.getAttribute('r'));
        const circleLength = 2 * Math.PI * radius;
        const initialStrokeDashOffset = circleLength;
        const targetStrokeDashOffset = initialStrokeDashOffset * 0.2;
        const rotationPoint = ActivityIndicator.shapeCenter;
        const initialTransform = `rotate(${ActivityIndicator.rotateAnimationStartDegree} ${rotationPoint} ${rotationPoint})`;
        const { animate } = this.props;
        $circle.setAttribute('transform', initialTransform);
        $circle.setAttribute('stroke-dasharray', circleLength.toString());
        $circle.setAttribute('stroke-dashoffset', initialStrokeDashOffset.toString());
        if (animate && !isDefined(this.animationInstance)) {
            this.animationInstance = anime.timeline();
            this.animationInstance.add({
                targets: $circle,
                easing: 'easeOutCubic',
                duration: ActivityIndicator.animationSegmentDuration,
                strokeDashoffset: [initialStrokeDashOffset, targetStrokeDashOffset],
            });
            this.playLoopAnimation();
        }
    }
    playLoopAnimation() {
        const $circle = this.svgCircleRef.current;
        const rotationPoint = ActivityIndicator.shapeCenter;
        const initialTransform = `rotate(${ActivityIndicator.rotateAnimationStartDegree} ${rotationPoint} ${rotationPoint})`;
        const targetTransform = `rotate(${ActivityIndicator.rotateAnimationEndDegree +
            360} ${rotationPoint} ${rotationPoint})`;
        const { animate } = this.props;
        if (animate) {
            anime({
                targets: $circle,
                duration: ActivityIndicator.animationSegmentDuration,
                easing: 'easeInOutSine',
                transform: [initialTransform, targetTransform],
                offset: 0,
                complete: instance => {
                    const shouldReplay = this.props.state === XHRState.Pending &&
                        this.props.animate === true;
                    if (shouldReplay) {
                        instance.restart();
                    }
                    else {
                        this.playLoopEnd(this.props.state);
                    }
                },
            });
        }
        else {
            this.playLoopEnd(this.props.state);
        }
    }
    playLoopEnd(state) {
        const { onAnimationEnd } = this.props;
        const $svgContainer = this.svgContainerRef.current;
        const $circle = this.svgCircleRef.current;
        const circleRadius = parseInt($circle.getAttribute('r'));
        const $checkIcon = this.svgCheckIconRef.current;
        const targetStrokeDashOffset = 0;
        const rotationPoint = ActivityIndicator.shapeCenter;
        const initialTransform = `rotate(${ActivityIndicator.rotateAnimationStartDegree} ${rotationPoint} ${rotationPoint})`;
        const targetTransform = `rotate(${ActivityIndicator.rotateAnimationStartDegree -
            ActivityIndicator.rotateAnimationOffset} ${rotationPoint} ${rotationPoint})`;
        anime.remove([$circle, $svgContainer]);
        const loopEndTimeline = anime.timeline({
            easing: 'easeOutCubic',
            complete: () => {
                if (onAnimationEnd)
                    onAnimationEnd();
                anime.remove([$circle, $svgContainer]);
            },
        });
        if (state === XHRState.Fulfilled) {
            const iconPathLength = $checkIcon.getTotalLength();
            const initialStrokeDashOffset = iconPathLength;
            $checkIcon.setAttribute('stroke-dasharray', iconPathLength.toString());
            $checkIcon.setAttribute('stroke-dashoffset', initialStrokeDashOffset.toString());
            loopEndTimeline.add({
                targets: $circle,
                duration: ActivityIndicator.animationSegmentDuration * 0.3,
                strokeDashoffset: 0,
                strokeWidth: circleRadius * 2,
                transform: [initialTransform, targetTransform],
            });
            loopEndTimeline.add({
                targets: $checkIcon,
                duration: ActivityIndicator.animationSegmentDuration * 0.4,
                easing: 'easeOutCubic',
                strokeDashoffset: [initialStrokeDashOffset, targetStrokeDashOffset],
                begin: function () {
                    anime.set($checkIcon, {
                        opacity: 1,
                    });
                },
            });
            loopEndTimeline.add({
                targets: $svgContainer,
                duration: ActivityIndicator.animationSegmentDuration * 0.4,
                keyframes: [{ scale: 0.95 }, { scale: 1 }],
            }, `-=${ActivityIndicator.animationSegmentDuration * 0.4}`);
        }
        else if (state === XHRState.Rejected) {
            loopEndTimeline.add({
                targets: $circle,
                duration: ActivityIndicator.animationSegmentDuration * 0.2,
                strokeDashoffset: 0,
                stroke: defaultErrorColor,
                transform: [initialTransform, targetTransform],
            });
            loopEndTimeline.add({
                targets: `.${classNames.cross}`,
                duration: ActivityIndicator.animationSegmentDuration * 0.3,
                opacity: 1,
            }, `-=${ActivityIndicator.animationSegmentDuration}`);
            loopEndTimeline.add({
                targets: $svgContainer,
                duration: ActivityIndicator.animationSegmentDuration * 0.5,
                keyframes: [
                    { translateX: 10 },
                    { translateX: -10 },
                    { translateX: 4 },
                    { translateX: -4 },
                    { translateX: 0 },
                ],
            }, `-=${ActivityIndicator.animationSegmentDuration * 0.3}`);
        }
        loopEndTimeline.add({
            targets: $svgContainer,
            duration: ActivityIndicator.animationSegmentDuration * 0.3,
            delay: ActivityIndicator.animationSegmentDuration * 0.4,
            opacity: 0,
        });
    }
}
ActivityIndicator.defaultProps = {
    backgroundColor: defaultBackgroundColor,
    circleColor: defaultCircleColor,
    iconColor: defaultIconColor,
    width: 40,
    height: 40,
};
ActivityIndicator.viewboxSize = 100;
ActivityIndicator.viewbox = `0 0 ${ActivityIndicator.viewboxSize} ${ActivityIndicator.viewboxSize}`;
ActivityIndicator.strokeWidth = 8;
ActivityIndicator.shapeCenter = ActivityIndicator.viewboxSize / 2;
ActivityIndicator.backgroundCircleRadius = 50;
ActivityIndicator.circleRadius = 50 - ActivityIndicator.strokeWidth / 2;
ActivityIndicator.animationSegmentDuration = 800;
ActivityIndicator.rotateAnimationOffset = 360 * 0.1;
ActivityIndicator.rotateAnimationStartDegree = -90 + ActivityIndicator.rotateAnimationOffset;
ActivityIndicator.rotateAnimationEndDegree = 270 + ActivityIndicator.rotateAnimationOffset;
__decorate([
    boundMethod
], ActivityIndicator.prototype, "playLoopAnimation", null);
__decorate([
    boundMethod
], ActivityIndicator.prototype, "playLoopEnd", null);
