var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
// components
import { Select } from '../Select';
import { Input } from '../Input';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { List } from '../List';
import { Form } from '../Form';
// styles
import { classNames } from './filterForm.style';
// utils
import { boundMethod, isDefined } from 'utilities';
export class FilterForm extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedFilter: this.props.selectOptions[0].value,
            selectedFilterLabel: this.props.selectOptions[0].label,
            filters: [],
        };
        this.newFilterInputRef = React.createRef();
    }
    render() {
        return (React.createElement("div", null,
            this.state.filters.length > 0 && (React.createElement(Form, { htmlAttributes: { className: classNames.filters }, submitOnEnter: true, onSubmit: this.updateFilters },
                React.createElement(List, { items: this.state.filters, template: (filter, index) => {
                        const key = filter.key + Date.now().toString(6);
                        return (React.createElement("div", { key: key, className: classNames.filter },
                            React.createElement("div", { className: classNames.filterLabel }, filter.label),
                            React.createElement(Input, { htmlAttributes: {
                                    name: filter.key,
                                    defaultValue: filter.value,
                                } }),
                            React.createElement(Button, { design: 1 /* Ghost */, onPress: this.removeFilter.bind(this, index), htmlAttributes: { type: 'button' } },
                                React.createElement(Icon, { icon: "times", className: classNames.buttonIcon }),
                                "filter verwijderen")));
                    } }))),
            React.createElement(Form, { htmlAttributes: {
                    className: classNames.add,
                }, onSubmit: this.addFilter },
                React.createElement(Select, { options: this.props.selectOptions, htmlAttributes: {
                        onChange: this.setFilter,
                    } }),
                React.createElement(Input, { ref: this.newFilterInputRef, htmlAttributes: {
                        placeholder: `voer ${this.state.selectedFilterLabel || ''} in`,
                    } }),
                React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { type: 'submit' } },
                    React.createElement(Icon, { icon: "plus", className: classNames.buttonIcon }),
                    "filter toevoegen"))));
    }
    setFilter(event) {
        this.setState({
            selectedFilter: event.target.value,
            selectedFilterLabel: event.target.selectedOptions[0].innerText,
        });
    }
    removeFilter(index) {
        this.setState(state => {
            const newFilters = state.filters.slice();
            newFilters.splice(index, 1);
            if (this.props.onSubmit)
                this.props.onSubmit(this.serializeFilters(newFilters));
            return {
                filters: newFilters,
            };
        });
    }
    addFilter(event) {
        const $target = event.target;
        const inputValue = this.newFilterInputRef.current.inputRef.current.value;
        if (inputValue.length === 0)
            return;
        this.setState((state) => {
            const newFilterIndex = state.filters.findIndex(function (filter) {
                return filter.key === state.selectedFilter;
            });
            let newFilter = null;
            let newState;
            if (newFilterIndex < 0) {
                newFilter =
                    newFilterIndex < 0
                        ? {
                            key: state.selectedFilter,
                            label: state.selectedFilterLabel,
                            value: inputValue,
                        }
                        : null;
                newState = {
                    filters: newFilter ? [...state.filters, newFilter] : state.filters,
                };
            }
            else {
                const newFilters = state.filters.slice();
                newFilters[newFilterIndex].key = state.selectedFilter;
                newFilters[newFilterIndex].label = state.selectedFilterLabel;
                newFilters[newFilterIndex].value = inputValue;
                newState = {
                    filters: newFilters,
                };
            }
            if (this.props.onSubmit)
                this.props.onSubmit(this.serializeFilters(newState.filters));
            $target.reset();
            return newState;
        });
    }
    updateFilters(event, data) {
        this.setState(state => {
            const keys = Object.keys(data);
            const newFilters = state.filters
                .map(filter => {
                let _filter;
                const value = data[filter.key];
                if (keys.indexOf(filter.key) > -1 &&
                    isDefined(value) &&
                    value.length > 0) {
                    _filter = {
                        key: filter.key,
                        label: filter.label,
                        value: value,
                    };
                }
                else if (!isDefined(value) || value.length === 0) {
                    return null;
                }
                return _filter;
            })
                .filter(filter => filter);
            if (this.props.onSubmit)
                this.props.onSubmit(data);
            return {
                filters: newFilters,
            };
        });
    }
    serializeFilters(filters) {
        const data = {};
        filters.forEach(function (filter) {
            data[filter.key] = filter.value;
        });
        return data;
    }
}
__decorate([
    boundMethod
], FilterForm.prototype, "setFilter", null);
__decorate([
    boundMethod
], FilterForm.prototype, "removeFilter", null);
__decorate([
    boundMethod
], FilterForm.prototype, "addFilter", null);
__decorate([
    boundMethod
], FilterForm.prototype, "updateFilters", null);
