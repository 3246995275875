// libs
// components
import { Icon } from '@ui-components/elements/Icon';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// styles
import { classNames } from './MenuItem.style';
class MenuItemClass extends React.Component {
    render() {
        const { description, icon, path } = this.props;
        const pathName = this.props.location.pathname.split('/')[1];
        const classNames = this.createContainerClassNames(pathName === path ? (path.replace('/', '') ? 'active' : '') : null);
        const iconContainerClassNames = this.createIconContainerClassNames();
        const iconClassNames = this.createIconClassNames();
        const childNodes = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: iconContainerClassNames },
                React.createElement(Icon, { icon: icon, className: iconClassNames })),
            description));
        return this.props.useAHref ? (React.createElement("a", Object.assign({ href: path, className: classNames, target: this.props.target }, this.props.linkProps), childNodes)) : (React.createElement(Link, Object.assign({}, this.props.linkProps, { to: path, className: classNames }), childNodes));
    }
    createContainerClassNames(...defaults) {
        return [...defaults, classNames.link, classNames.menuItem].join(' ').trim();
    }
    createIconContainerClassNames(...defaults) {
        return [...defaults, 'icon', classNames.iconContainer].join(' ').trim();
    }
    createIconClassNames(...defaults) {
        return [...defaults, 'icon', classNames.icon].join(' ').trim();
    }
}
export const MenuItem = withRouter(MenuItemClass);
