var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var OrdersScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
// components
import { DataManager } from '@ui-components/elements/DataManager';
import { Page } from '@ui-components/elements/Page';
// store
import { ordersStore, productsStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
let OrdersScreen = OrdersScreen_1 = class OrdersScreen extends React.Component {
    constructor() {
        super(...arguments);
        this.includeProcessors = this.createKeyProcessors();
    }
    createKeyProcessors() {
        const procs = new Array(OrdersScreen_1.includeKeys.length);
        procs[0] = function (date) {
            return date.split('T')[0];
        };
        procs[2] = function (price = 0, item) {
            let extrasPrice = 0;
            item.extraProducts.forEach(product => (extrasPrice += product.price));
            return productsStore.parsePrice(price + extrasPrice);
        };
        procs[3] = ordersStore.statusToString;
        return procs;
    }
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: ordersStore.state },
                React.createElement(DataManager, Object.assign({}, this.props, { create: false, data: ordersStore.orders, dataIdentifierKey: "_id", includeKeys: OrdersScreen_1.includeKeys, keyLabels: OrdersScreen_1.keyHeaders, includeProcessors: this.includeProcessors, state: ordersStore.state, subjectName: "offerte", subjectNamePlural: "offertes", title: "Offertes", url: "orders", fetchHandler: this.fetchOrders, defaultSortBy: '-createdAt' })))));
    }
    fetchOrders(params) {
        ordersStore.fetchOrders(params);
    }
};
OrdersScreen.includeKeys = [
    'createdAt',
    'modelProduct.name',
    'modelProduct.price',
    'status',
    'dealer.store',
    'clientName',
    'clientAddress',
    'clientCity',
    'clientCountry',
];
OrdersScreen.keyHeaders = [
    'datum',
    'product',
    'prijs',
    'status',
    'dealer',
    'naam klant',
    'adres klant',
    'plaats klant',
    'land klant',
];
OrdersScreen = OrdersScreen_1 = __decorate([
    observer
], OrdersScreen);
export { OrdersScreen };
