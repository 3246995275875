var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var UserScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Icon } from '@ui-components/elements/Icon';
// components
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
import { SearchInput } from '@ui-components/elements/SearchInput';
// store
import { dealersStore, usersStore } from 'henra-store';
import get from 'lodash-es/get';
import { observer } from 'mobx-react';
import React from 'react';
// utils
import { boundMethod } from 'utilities';
// styles
import { classNames } from './user.style';
let UserScreen = UserScreen_1 = class UserScreen extends React.Component {
    constructor(props) {
        super(props);
        this.searchInputRef = React.createRef();
        this.state = {
            searchResults: [],
            selectedDealer: null,
        };
        const user = usersStore.userToEdit;
        const userId = props.match.params['id'];
        if (!user || user._id !== userId) {
            usersStore.fetchUser(userId);
        }
    }
    render() {
        const user = usersStore.userToEdit || this.getEmptyUser();
        const { searchResults } = this.state;
        const searchInputValue = get(this.state, ['selectedDealer', 'store']);
        const formTitle = `Gebruiker${user.firstName && user.lastName
            ? `: ${user.firstName} ${user.lastName}`
            : ''}`;
        const searchInputProps = {
            label: 'Wijs een dealer toe',
            htmlAttributes: {
                defaultValue: get(user, ['dealer', 'store']) || '',
            },
        };
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: usersStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.handleBackButtonPress },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            "gebruikers")),
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement(ModelEditor, { submitOnEnter: false, prependChildren: React.createElement(SearchInput, { ref: this.searchInputRef, onSubmit: this.searchDealers, data: searchResults, useAsKey: '_id', useResultKey: 'store', useResultIcon: 'user-tie', onResultPress: this.handleResultPress, inputProps: searchInputProps, value: searchInputValue }), title: formTitle, data: user, include: this.getIncludes(UserScreen_1.includeUserKeys, UserScreen_1.includeUserLabels), onSubmit: this.handleSubmit, onDelete: this.handleDelete })))))));
    }
    getEmptyUser() {
        let user = {};
        UserScreen_1.includeUserKeys.forEach(key => {
            user[key] = '';
        });
        return user;
    }
    handleDelete() {
        const userId = this.props.match.params['id'];
        const shouldDelete = confirm('Weet u zeker dat u deze gebruiker wil verwijderen?');
        if (shouldDelete) {
            usersStore.deleteUser(userId).then(() => {
                this.props.history.push('/users');
            });
        }
    }
    handleSubmit(event, data) {
        console.log('handle submit');
        const body = Object.assign(data, {
            _id: this.props.match.params['id'],
            dealer: get(this.state, ['selectedDealer', '_id']) || null,
            search: undefined,
        });
        console.log('update user');
        usersStore.updateUser(body);
    }
    handleResultPress(item) {
        this.searchInputRef.current.inputRef.current.inputRef.current.value =
            item.store;
        this.setState({
            selectedDealer: item,
        });
    }
    searchDealers(value) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const results = yield dealersStore.searchDealers(value, false);
                this.setState({
                    searchResults: results,
                });
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    handleBackButtonPress() {
        this.props.history.push('/users');
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            return {
                key: field,
                inputProps: {
                    label: labels[index],
                },
            };
        });
    }
};
UserScreen.includeUserKeys = [
    'firstName',
    'lastName',
    'email',
];
UserScreen.includeUserLabels = [
    'voornaam',
    'achternaam',
    'e-mailadres',
];
__decorate([
    boundMethod
], UserScreen.prototype, "handleDelete", null);
__decorate([
    boundMethod
], UserScreen.prototype, "handleSubmit", null);
__decorate([
    boundMethod
], UserScreen.prototype, "handleResultPress", null);
__decorate([
    boundMethod
], UserScreen.prototype, "searchDealers", null);
__decorate([
    boundMethod
], UserScreen.prototype, "handleBackButtonPress", null);
UserScreen = UserScreen_1 = __decorate([
    observer
], UserScreen);
export { UserScreen };
