import { styleController, mixins } from 'style';
const { spacing, colors } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: spacing.vertical,
        }),
        mixins.column(),
    ].join(' '),
    formContainer: styleController.create({
        flex: 1,
        overflow: 'hidden',
        borderTop: `1px solid ${colors.henra}`,
    }),
    top: mixins.row(1, true),
    buttonIcon: styleController.create({
        marginRight: spacing.horizontal,
    }),
};
