var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import { List } from '@ui-components/elements/List';
import { MenuItem } from '@ui-components/elements/MenuItem';
import { Title } from '@ui-components/elements/Title';
// store
import { authenticationStore, usersStore } from 'henra-store';
import get from 'lodash-es/get';
import { observer } from 'mobx-react';
import React from 'react';
// components
import { routes } from 'routes';
import { isDefined } from 'utilities';
import { ScrollView } from '../ScrollView';
import { UserElement } from '../User';
// styles
import { classNames } from './MainNavigationMenu.style';
let MainNavigationMenu = class MainNavigationMenu extends React.Component {
    render() {
        const containerClassNames = this.createContainerClassNames(this.props.className);
        const urlSuffix = authenticationStore.authenticated
            ? `?token=${authenticationStore.getToken(true)}`
            : '';
        const menuItems = routes(get(usersStore, ['me', 'role'])).filter(function (route) {
            return isDefined(route.menuInfo);
        });
        const urls = this.getConfiguratorUrls(urlSuffix);
        return (React.createElement("div", { className: containerClassNames },
            React.createElement(ScrollView, { height: "100%" },
                React.createElement(UserElement, null),
                React.createElement(Title, { size: 0, className: classNames.title }, "DASHBOARD"),
                React.createElement(List, { items: menuItems, template: this.createMenuItemNode }),
                React.createElement(Title, { size: 0, className: classNames.title }, "CONFIGURATOR"),
                React.createElement(List, { items: urls, template: this.createConfiguratorMenuItemNode }),
                React.createElement(Title, { size: 0, className: classNames.title }, "ACCOUNT"),
                React.createElement(MenuItem, { description: "Uitloggen", icon: "sign-out-alt", path: "/login", linkProps: {
                        onClick: this.logout,
                    } }))));
    }
    componentDidMount() {
        usersStore.fetchMe();
    }
    createContainerClassNames(...defaults) {
        return [...defaults, classNames.container].join(' ').trim();
    }
    getConfiguratorUrls(suffix) {
        return [
            {
                description: 'Bakwagen',
                url: `${process.env.CONFIGURATOR_URL}bakwagen`,
            },
            {
                description: 'Gesloten bakwagen',
                url: `${process.env.CONFIGURATOR_URL}geslotenbakwagen`,
            },
            {
                description: 'Kipper drie-zijdig',
                url: `${process.env.CONFIGURATOR_URL}kipper-3zijdig`,
            },
            {
                description: 'Kipper achterwaarts',
                url: `${process.env.CONFIGURATOR_URL}kipper-achterwaarts`,
            },
            {
                description: 'Minigraver',
                url: `${process.env.CONFIGURATOR_URL}minigraver`,
            },
            {
                description: 'Plateauwagen',
                url: `${process.env.CONFIGURATOR_URL}plateauwagen-basis`,
            },
            {
                description: 'Plateauwagen verlaagd',
                url: `${process.env.CONFIGURATOR_URL}plateauwagen-verlaagd`,
            },
            {
                description: 'Plateauwagen XL',
                url: `${process.env.CONFIGURATOR_URL}plateauwagen-xl`,
            },
            {
                description: 'Transportwagen',
                url: `${process.env.CONFIGURATOR_URL}transportwagen`,
            },
        ].map(item => {
            item.url += suffix;
            return item;
        });
    }
    createMenuItemNode(route) {
        const path = route.path.toString();
        const description = route.menuInfo.description;
        const icon = route.menuInfo.icon;
        const props = {
            path: path,
            description: description,
            icon: icon,
        };
        return React.createElement(MenuItem, Object.assign({ key: path }, props));
    }
    createConfiguratorMenuItemNode(cUrl) {
        return (React.createElement(MenuItem, { key: cUrl.description, description: cUrl.description, icon: "external-link-alt", useAHref: true, linkProps: {
                onClick: e => {
                    e.preventDefault();
                    const path = cUrl.url;
                    const windowFeatures = [
                        'menubar=no',
                        'toolbar=no',
                        'location=no',
                        'personalbar=no',
                        'status=no',
                        `width=${window.innerWidth * 0.8}`,
                        `height=${window.innerHeight * 0.8}`,
                    ].join(',');
                    window.open(path, 'blank', windowFeatures);
                },
            } }));
    }
    logout() {
        authenticationStore.logout();
    }
};
MainNavigationMenu = __decorate([
    observer
], MainNavigationMenu);
export { MainNavigationMenu };
