var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import React from 'react';
import debounce from 'lodash-es/debounce';
// components
import { Input } from '@ui-components/elements/Input';
import { List } from '@ui-components/elements/List';
import { QuickSearchResult, } from '@ui-components/elements/QuickSearchResult';
// style
import { classNames } from './SearchInput.style';
// utils
import { boundMethod, isDefined } from 'utilities';
export class SearchInput extends React.Component {
    constructor() {
        super(...arguments);
        this.inputRef = React.createRef();
        this.state = {
            showResults: false,
        };
        this.searchInputHTMLProps = {
            placeholder: '',
            className: classNames.searchInput,
            name: 'search',
            onChange: this.handleFormChange,
            autoComplete: 'off',
            onFocus: this.handleFormFocus,
            onBlur: this.handleFormBlur,
        };
        this.delayedCallback = debounce((event) => {
            const value = event.target.value;
            if (value.length > 0 && isDefined(this.props.onSubmit))
                this.props.onSubmit(value);
        }, SearchInput.callbackDelay, {
            leading: true,
        });
    }
    render() {
        const results = this.props.data || [];
        const { showResults } = this.state;
        const containerClassNames = this.createClassNames();
        const inputHTMLAttributes = this.createInputHTMLAttributes();
        return (React.createElement("div", { className: containerClassNames },
            React.createElement(Input, Object.assign({}, this.props.inputProps, { ref: this.inputRef, className: classNames.searchInput, htmlAttributes: inputHTMLAttributes, icon: "search" })),
            showResults && (React.createElement("div", { className: classNames.results },
                React.createElement(List, { items: results, template: this.createResultNode })))));
    }
    createInputHTMLAttributes() {
        const { inputProps } = this.props;
        const assign = {};
        if (isDefined(inputProps) && isDefined(inputProps.htmlAttributes)) {
            Object.assign(assign, inputProps.htmlAttributes);
        }
        const result = Object.assign(this.searchInputHTMLProps, assign);
        return result;
    }
    createClassNames() {
        const classNameProp = this.props.className;
        return [classNames.container, classNameProp].join(' ').trim();
    }
    handleFormFocus() {
        this.setState({
            showResults: true,
        });
    }
    handleFormBlur() {
        setTimeout(() => {
            this.setState({
                showResults: false,
            });
        }, SearchInput.callbackDelay);
    }
    handleFormSubmit(event, formData) {
        const search = formData.search;
        if (search && isDefined(this.props.onSubmit))
            this.props.onSubmit(search);
    }
    handleFormChange(event) {
        event.persist();
        this.delayedCallback(event);
    }
    createResultNode(item, index, useAsKey) {
        const key = this.props.useResultKey;
        const props = {
            icon: this.props.useResultIcon,
            text: Array.isArray(key)
                ? key
                    .map(key => {
                    return item[key];
                })
                    .join(' ')
                : item[key],
        };
        const pressCallback = this.props.onResultPress;
        return (React.createElement(QuickSearchResult, Object.assign({ key: useAsKey }, props, { onPress: pressCallback ? pressCallback.bind(this, item) : null })));
    }
}
SearchInput.callbackDelay = 300;
__decorate([
    boundMethod
], SearchInput.prototype, "handleFormFocus", null);
__decorate([
    boundMethod
], SearchInput.prototype, "handleFormBlur", null);
__decorate([
    boundMethod
], SearchInput.prototype, "handleFormChange", null);
__decorate([
    boundMethod
], SearchInput.prototype, "createResultNode", null);
