export function filterFormInputs($form, attributes) {
    let _results = [];
    for (let input of $form) {
        for (let key of Object.keys(attributes)) {
            if (input[key] === attributes[key]) {
                _results.push(input);
            }
        }
    }
    return _results;
}
