// libs
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faBars, faBoxOpen, faCaretDown, faCloudDownloadAlt, faDownload, faExternalLinkAlt, faFile, faFileAlt, faFolder, faList, faPlus, faSearch, faSignOutAlt, faSort, faSortDown, faSortUp, faTimes, faTrash, faTruck, faUser, faUsers, faUserTie, faVideo, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import {} from "@fortawesome/free-regular-svg-icons";
library.add(faList, faUserTie, faUsers, faSort, faSortUp, faSortDown, faArrowLeft, faSignOutAlt, faPlus, faTimes, faTruck, faFile, faDownload, faExternalLinkAlt, faUser, faCaretDown, faFolder, faVideo, faBars, faFileAlt, faSearch, faBoxOpen, faCloudDownloadAlt, faTrash);
export class Icon extends React.Component {
    render() {
        const props = this.props;
        return React.createElement(FontAwesomeIcon, Object.assign({}, props));
    }
}
