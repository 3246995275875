import { style as createStyle, cssRule, cssRaw } from 'typestyle';
import { normalize, setupPage } from 'csstips';
import Color from 'color';
class StyleController {
    constructor() {
        this.elementResetStack = [
            'button',
            'input',
            'optgroup',
            'select',
            'textarea',
            `html input[type="button"]`,
            `input[type="reset"]`,
            `input[type="submit"]`,
            `button[disabled]`,
            `html input[disabled]`,
            `button::-moz-focus-inner`,
            `input::-moz-focus-inner`,
            `input[type="checkbox"]`,
            `input[type="radio"]`,
            `input[type="number"]::-webkit-inner-spin-button`,
            `input[type="number"]::-webkit-outer-spin-button`,
            `input[type="search"]`,
            `input[type="search"]::-webkit-search-cancel-button`,
            `input[type="search"]::-webkit-search-decoration`,
        ].join(',');
        this.systemFontStack = [
            'system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen-Sans',
            'Ubuntu,Cantarell',
            'Helvetica Neue',
            'sans-serif',
        ];
        this.fontWeights = [400, 700, 800];
        this.googleFontStack = ['Open+Sans'];
        this.headerFontStack = [
            ...this.googleFontStack.map(function (font) {
                return `'${font.replace('+', ' ')}'`;
            }),
            ...this.systemFontStack,
        ].join(',');
        this.bodyFontStack = [
            ...this.googleFontStack.map(function (font) {
                return `'${font.replace('+', ' ')}'`;
            }),
            ...this.systemFontStack,
        ].join(',');
        this.colorShadesLight = [0.2, 0.5, 1];
        this.colorShadesDark = [0.1, 0.3, 0.5];
        this.colors = StyleController.colors;
        this.borderRadii = [4, 8, 14];
        this.spacing = 8;
        this.horizontalSpacingFactor = 1;
        this.verticalSpacingFactor = 1.5;
        this.create = createStyle;
        this.rule = cssRule;
        this.guide = {
            colors: this.colors,
            borderRadii: this.borderRadii,
            fonts: {
                header: this.headerFontStack,
                body: this.bodyFontStack,
            },
            fontWeights: {
                header: this.fontWeights,
                body: this.fontWeights,
            },
            spacing: {
                base: this.spacing,
                horizontal: this.spacing * this.horizontalSpacingFactor,
                vertical: this.spacing * this.verticalSpacingFactor,
            },
            animationDurations: {
                feedback: 100,
                effect: 200,
            },
        };
        // fonts
        this.googleFontStack.forEach(font => {
            cssRaw(`@import url('https://fonts.googleapis.com/css?family=${font}:${this.fontWeights.join(',')}');`);
        });
        setupPage('#root');
        normalize();
        cssRule(this.elementResetStack, {
            background: 'none',
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            border: 'none',
            boxShadow: 'none',
            color: 'inherit',
            font: 'inherit',
            outline: 'inherit',
            padding: 0,
        });
        cssRule('html', {
            fontSize: '14px',
        });
        cssRule('body', {
            color: this.colors.text.toString(),
            fontFamily: this.bodyFontStack,
            fontSize: '1rem',
        });
        cssRaw(`
      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    `);
        cssRule('*', {
            '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
        });
    }
    createBoxShadow(xOffset = 0, yOffset = 0, blur = 5, opacity = 0.3) {
        return `${xOffset}px ${yOffset}px ${blur}px rgba(0,0,0,${opacity})`;
    }
}
// https://flatuicolors.com/palette/us
StyleController.colors = {
    text: Color('#131313'),
    cityLights: Color('#dfe6e9'),
    henra: Color('rgb(214,73,76)'),
    pinkGlamour: Color('#ff7675'),
    americanRiver: Color('#636e72'),
    soothingBreeze: Color('#b2bec3'),
    mintLeaf: Color('#00b894'),
    firstDate: Color('#fab1a0'),
};
export const styleController = new StyleController();
