import React from 'react';
import get from 'lodash-es/get';
// components
import { Title } from '../Title';
import { List } from '../List';
export class ProductView extends React.Component {
    render() {
        const title = get(this.props, ['product', 'name']) || '';
        const description = get(this.props, ['product', 'description']) || '';
        const extras = (get(this.props, ['product', 'extras']) || '')
            .split('\n')
            .map((extra) => extra.trim().replace('- ', ''))
            .filter((extra) => extra);
        return (React.createElement(React.Fragment, null,
            React.createElement(Title, { size: 2 }, title),
            React.createElement("p", null, description),
            React.createElement("ul", null,
                React.createElement(List, { items: extras, template: this.createListItemNode }))));
    }
    createListItemNode(value, index) {
        return React.createElement("li", { key: index }, value);
    }
}
