import { styleController } from 'style';
const { colors, spacing, fontWeights, fonts, borderRadii, } = styleController.guide;
const buttonHoverColor = colors.pinkGlamour.toString();
const buttonCTAColor = colors.henra.toString();
const buttonGhostColor = colors.henra.toString();
const buttonBorderCTA = `1px solid ${buttonCTAColor}`;
const buttonBorderGhost = `1px solid ${buttonGhostColor}`;
const buttonBorderRadius = borderRadii[0];
const buttonBoxShadow = styleController.createBoxShadow(0, 2, 6, 0.2);
const buttonGhostBoxShadow = styleController.createBoxShadow(0, 0, 0, 0);
const buttonFontFamily = fonts.header;
const buttonFontWeight = fontWeights.header[0];
const buttonCTAFontWeight = fontWeights.header[1];
const buttonActiveBoxShadow = styleController.createBoxShadow(0, 1, 3, 0);
const buttonPadding = `${spacing.vertical / 1.5}px ${spacing.horizontal * 2}px`;
const buttonTransition = 'box-shadow 100ms linear, opacity 100ms linear, transform 100ms linear, background-color 100ms linear, border-color 100ms linear';
export const classNames = {
    button: styleController.create({
        '-webkit-appearance': 'none',
        borderRadius: buttonBorderRadius,
        boxSizing: 'border-box',
        cursor: 'pointer',
        fontFamily: buttonFontFamily,
        fontSize: '0.85rem',
        fontWeight: buttonFontWeight,
        padding: buttonPadding,
        position: 'relative',
        transition: buttonTransition,
        userSelect: 'none',
        $nest: {
            '&::after': {
                position: 'absolute',
                borderRadius: 'inherit',
                left: -1,
                top: -1,
                width: 'calc(100% + 2px)',
                height: 'calc(100% + 2px)',
                content: "' '",
                zIndex: -1,
                boxShadow: buttonBoxShadow,
                transition: buttonTransition,
            },
            '&:hover': {
                backgroundColor: buttonHoverColor,
                borderColor: buttonHoverColor,
                transitionDuration: '0',
                $nest: {
                    '&::after': {
                        boxShadow: buttonActiveBoxShadow,
                    },
                },
            },
            '&:active': {
                opacity: 0.8,
                outline: 'none',
                transform: 'translateY(-0.5px)',
            },
            '&:focus': {
                outline: 'none',
            },
        },
    }),
    cta: styleController.create({
        background: buttonCTAColor,
        border: buttonBorderCTA,
        color: 'white',
        fontWeight: buttonCTAFontWeight,
    }),
    ghost: styleController.create({
        background: 'transparent',
        border: buttonBorderGhost,
        color: buttonGhostColor,
        $nest: {
            '&::after': {
                position: 'absolute',
                borderRadius: 'inherit',
                left: -1,
                top: -1,
                width: 'calc(100% + 2px)',
                height: 'calc(100% + 2px)',
                content: "' '",
                zIndex: -1,
                boxShadow: buttonGhostBoxShadow,
                transition: buttonTransition,
            },
            '&:hover': {
                color: 'white',
                transitionDuration: '0',
            },
        },
    }),
};
