import { styleController, mixins } from 'style';
const { spacing, colors, fontWeights, borderRadii } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        }),
        mixins.row(1, true),
        mixins.column(),
    ].join(' '),
    listContainer: styleController.create({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        $nest: {
            '& > div:nth-child(2)': {
                flex: 1,
            },
        },
    }),
    form: [
        styleController.create({
            borderTop: `1px solid ${colors.henra}`,
            borderBottom: `1px solid ${colors.henra}`,
            display: 'block',
            $nest: {
                '& > div, & > button': {
                    margin: `0 ${spacing.horizontal * 2}px`,
                    display: 'inline-block',
                },
            },
        }),
        mixins.row(1, true),
    ].join(' '),
    fileListContainer: styleController.create({
        flex: 1,
    }),
    file: [
        styleController.create({
            display: 'flex',
            alignItems: 'center',
            borderRadius: borderRadii[0],
            border: `1px solid ${colors.cityLights}`,
            $nest: {
                '&:hover': {
                    $nest: {
                        '& .file-name': {
                            textDecoration: 'underline',
                        },
                    },
                },
            },
        }),
        mixins.row(1, true),
        mixins.row(),
        mixins.column(2),
    ].join(' '),
    preview: styleController.create({
        width: 50,
        height: 50,
        marginRight: spacing.horizontal * 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        background: colors.cityLights.toString(),
        borderRadius: borderRadii[0],
        color: colors.henra.toString(),
        $nest: {
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
        },
    }),
    info: styleController.create({
        $nest: {
            '& > div:last-child': {
                color: colors.americanRiver.toString(),
                fontSize: '.9rem',
            },
        },
    }),
    infoRow: mixins.row(0.5),
    fileName: styleController.create({
        fontWeight: fontWeights.body[1],
        marginRight: spacing.horizontal / 2,
    }),
    fileTypeLabel: styleController.create({
        color: colors.americanRiver.toString(),
    }),
    fileButton: styleController.create({
        width: 40,
        padding: spacing.base,
        $nest: {
            '&:first-of-type': {
                marginRight: 8,
            },
        },
    }),
};
