var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ProductsScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { DataManager } from '@ui-components/elements/DataManager';
import { Page } from '@ui-components/elements/Page';
import { productsStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
import { prune } from 'utilities';
let ProductsScreen = ProductsScreen_1 = class ProductsScreen extends React.Component {
    constructor() {
        super(...arguments);
        this.includeProcessors = this.createKeyProcessors();
    }
    createKeyProcessors() {
        const procs = new Array(ProductsScreen_1.includeKeys.length);
        procs[2] = function (price = 0, item) {
            return productsStore.parsePrice(price);
        };
        procs[3] = function (description) {
            const limit = 50;
            return (prune(description, limit) + (description.length > limit ? '...' : ''));
        };
        return procs;
    }
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: productsStore.state },
                React.createElement(DataManager, Object.assign({}, this.props, { data: productsStore.products, dataIdentifierKey: "_id", includeKeys: ProductsScreen_1.includeKeys, keyLabels: ProductsScreen_1.keyHeaders, includeProcessors: this.includeProcessors, subjectName: "product", newSubjectWord: "nieuw", subjectNamePlural: "producten", url: "products", title: "Producten", state: productsStore.state, 
                    // onFilter={this.handleFilter}
                    // onSort={productsStore.sortProducts}
                    fetchHandler: this.fetchProducts })))));
    }
    fetchProducts(params) {
        productsStore.fetchProducts(params);
    }
};
ProductsScreen.includeKeys = [
    'name',
    'dimensions',
    'price',
    'description',
    'productId',
];
ProductsScreen.keyHeaders = [
    'wagen',
    'afmeting',
    'prijs',
    'beschrijving',
    'model',
];
ProductsScreen = ProductsScreen_1 = __decorate([
    observer
], ProductsScreen);
export { ProductsScreen };
