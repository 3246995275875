import { styleController, mixins } from 'style';
const { spacing, borderRadii, colors } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            height: 44,
            cursor: 'pointer',
        }),
        mixins.column(),
    ].join(' '),
    layout: styleController.create({ display: 'flex', alignItems: 'center' }),
    logoContainer: [
        styleController.create({
            marginRight: spacing.horizontal * 2,
            width: 44,
            height: 44,
            borderRadius: borderRadii[1],
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: colors.cityLights.toString(),
            flexShrink: 0,
            $nest: {
                '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                },
            },
        }),
    ].join(' '),
    icon: styleController.create({
        color: colors.henra.toString(),
    }),
};
