var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { assetsStore } from 'henra-store/assets';
// types
import { XHRState } from 'henra-store/services/XHRState';
import { action, observable } from 'mobx';
import { boundMethod } from 'utilities';
// service
import { dealersService } from './dealers.service';
class DealersStore {
    constructor() {
        this.dealers = null;
        this.dealerToEdit = null;
    }
    fetchDealer(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.dealerToEdit = null;
            this.state = XHRState.Pending;
            try {
                const response = yield dealersService.fetchDealer(id);
                this.dealerToEdit = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchDealers(params, set = true) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield dealersService.fetchDealers(params);
                if (set)
                    this.dealers = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    searchDealers(search, set = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = `?search=${search}`;
            this.state = XHRState.Pending;
            try {
                const response = yield dealersService.fetchDealers(urlParams);
                if (set)
                    this.dealers = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    updateDealer(data, assetFormData) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                let _assetResponse;
                if (assetFormData) {
                    _assetResponse = yield assetsStore.uploadAsset(assetFormData);
                }
                const response = yield dealersService.updateDealer(Object.assign(Object.assign({}, data), { image: _assetResponse
                        ? _assetResponse._id
                        : null }));
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    sortDealers(by, direction) {
        this.dealers = this.dealers.slice().sort(function (a, b) {
            if (a[by] < b[by])
                return -1 * direction;
            if (a[by] > b[by])
                return 1 * direction;
            return 0;
        });
    }
    createDealer(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield dealersService.createDealer(data);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
    removeDealer(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield dealersService.removeDealer(data._id);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
}
__decorate([
    observable
], DealersStore.prototype, "state", void 0);
__decorate([
    observable
], DealersStore.prototype, "dealers", void 0);
__decorate([
    observable
], DealersStore.prototype, "dealerToEdit", void 0);
__decorate([
    action
], DealersStore.prototype, "fetchDealer", null);
__decorate([
    action
], DealersStore.prototype, "fetchDealers", null);
__decorate([
    action
], DealersStore.prototype, "searchDealers", null);
__decorate([
    action
], DealersStore.prototype, "updateDealer", null);
__decorate([
    boundMethod,
    action
], DealersStore.prototype, "sortDealers", null);
__decorate([
    action
], DealersStore.prototype, "createDealer", null);
__decorate([
    action
], DealersStore.prototype, "removeDealer", null);
export const dealersStore = new DealersStore();
