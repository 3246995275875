var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
// components
import { Form } from '@ui-components/elements/Form';
import { List } from '@ui-components/elements/List';
import { Input } from '@ui-components/elements/Input';
// components
import { Button } from '../Button';
import { Title } from '../Title';
// styles
import { classNames } from './modelEditor.style';
// utils
import { boundMethod, isDefined } from 'utilities';
export class ModelEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showExtraSaveButton: false,
            showAdvancedOptions: false,
        };
        this.containerRef = React.createRef();
    }
    render() {
        const { showAdvancedOptions } = this.state;
        const advancedButtonText = `geavanceerde opties ${showAdvancedOptions ? 'verbergen' : 'weergeven'}`;
        const deleteButtonText = 'verwijderen';
        return (React.createElement("div", { className: classNames.container, ref: this.containerRef },
            this.props.title && (React.createElement(Title, { className: classNames.title, size: 2 }, this.props.title)),
            React.createElement(Form, { htmlAttributes: { className: classNames.form }, onSubmit: this.props.onSubmit, submitOnEnter: this.props.submitOnEnter },
                this.state.showExtraSaveButton && (React.createElement(Button, { htmlAttributes: ModelEditor.inputHTMLAttributes }, "opslaan")),
                this.props.prependChildren,
                React.createElement(List, { items: this.props.include, template: this.createInputNode }),
                this.props.appendChildren,
                isDefined(this.props.onDelete) && (React.createElement("div", { className: classNames.advanced },
                    React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { type: 'button' }, onPress: this.toggleAdvancedOptions }, advancedButtonText),
                    showAdvancedOptions && (React.createElement("div", { className: classNames.advanced },
                        React.createElement(Button, { htmlAttributes: { type: 'button' }, onPress: this.handleDelete }, deleteButtonText))))),
                React.createElement(Button, { htmlAttributes: ModelEditor.inputHTMLAttributes }, "opslaan"))));
    }
    componentDidMount() {
        this.showExtraButton();
    }
    componentDidUpdate() {
        this.showExtraButton();
    }
    handleDelete(event) {
        this.props.onDelete(event, this.props.data);
    }
    toggleAdvancedOptions() {
        this.setState(state => ({
            showAdvancedOptions: !state.showAdvancedOptions,
        }));
    }
    showExtraButton() {
        requestAnimationFrame(() => {
            const $container = this.containerRef.current;
            if (!$container)
                return;
            const $parent = $container.parentElement;
            const containerHeight = parseInt(getComputedStyle($container).height);
            const parentHeight = parseInt(getComputedStyle($parent).height);
            if (containerHeight > parentHeight) {
                this.setState({
                    showExtraSaveButton: true,
                });
            }
        });
    }
    createInputNode(item, index) {
        const key = item.key;
        const defaultValue = this.props.data[key];
        const inputProps = item.inputProps || {};
        const propsHTMLAttributes = isDefined(item.inputProps)
            ? inputProps.htmlAttributes || {}
            : {};
        const HTMLAttributes = Object.assign({
            name: key,
            defaultValue: defaultValue,
        }, propsHTMLAttributes);
        return React.createElement(Input, Object.assign({}, inputProps, { htmlAttributes: HTMLAttributes, key: key }));
    }
}
ModelEditor.inputHTMLAttributes = { type: 'submit' };
__decorate([
    boundMethod
], ModelEditor.prototype, "handleDelete", null);
__decorate([
    boundMethod
], ModelEditor.prototype, "toggleAdvancedOptions", null);
__decorate([
    boundMethod
], ModelEditor.prototype, "createInputNode", null);
