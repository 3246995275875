var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var MeScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Input } from '@ui-components/elements/Input';
// components
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
import { SearchInput } from '@ui-components/elements/SearchInput';
// store
import { dealersStore, usersStore } from 'henra-store';
import get from 'lodash-es/get';
import { observer } from 'mobx-react';
import React from 'react';
// utils
import { boundMethod, filterFormInputs, getUserDisplayName } from 'utilities';
// styles
import { classNames } from './me.style';
let MeScreen = MeScreen_1 = class MeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.searchInputRef = React.createRef();
        this.state = {
            searchResults: [],
            selectedDealer: null,
        };
        const me = usersStore.me;
        if (!me) {
            usersStore.fetchMe();
        }
    }
    render() {
        const me = usersStore.me || this.getEmptyMe();
        const { searchResults } = this.state;
        const searchInputValue = get(this.state, ['selectedDealer', 'store']);
        return me ? (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: usersStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement(ModelEditor, { title: getUserDisplayName(me), data: me, state: usersStore.state, submitOnEnter: false, prependChildren: React.createElement(React.Fragment, null,
                                    React.createElement(SearchInput, { ref: this.searchInputRef, onSubmit: this.searchDealers, data: searchResults, useAsKey: '_id', useResultKey: 'store', useResultIcon: 'user-tie', onResultPress: this.handleResultPress, inputProps: {
                                            label: 'Wijs een dealer toe',
                                            htmlAttributes: {
                                                defaultValue: get(me, ['dealer', 'store']) || '',
                                            },
                                        }, value: searchInputValue }),
                                    React.createElement(Input, { label: "Logo", htmlAttributes: {
                                            type: 'file',
                                            accept: 'image/*',
                                        } })), include: this.getIncludes(MeScreen_1.includeMeKeys, MeScreen_1.includeMeLabels), onSubmit: this.handleFormSubmit }))))))) : null;
    }
    handleFormSubmit(event, data) {
        const $form = event.target;
        const $fileInput = filterFormInputs($form, {
            type: 'file',
        })[0];
        const files = $fileInput.files;
        const formData = new FormData();
        const body = Object.assign(data, {
            dealer: get(this.state, ['selectedDealer', '_id']) || null,
        });
        for (let file of files) {
            formData.append('file', file, file.name);
        }
        usersStore.updateMe(body, files.length > 0 ? formData : null);
    }
    handleResultPress(item) {
        this.searchInputRef.current.inputRef.current.inputRef.current.value =
            item.store;
        this.setState({
            selectedDealer: item,
        });
    }
    searchDealers(value) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const results = yield dealersStore.searchDealers(value, false);
                this.setState({
                    searchResults: results,
                });
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    getEmptyMe() {
        let me = {};
        MeScreen_1.includeMeKeys.forEach(key => {
            me[key] = '';
        });
        return me;
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            return {
                key: field,
                inputProps: {
                    label: labels[index],
                },
            };
        });
    }
};
MeScreen.includeMeKeys = [
    'firstName',
    'lastName',
    'email',
];
MeScreen.includeMeLabels = ['voornaam', 'achternaam', 'e-mailadres'];
__decorate([
    boundMethod
], MeScreen.prototype, "handleFormSubmit", null);
__decorate([
    boundMethod
], MeScreen.prototype, "handleResultPress", null);
__decorate([
    boundMethod
], MeScreen.prototype, "searchDealers", null);
MeScreen = MeScreen_1 = __decorate([
    observer
], MeScreen);
export { MeScreen };
