import { styleController, mixins } from 'style';
const separatorColor = styleController.guide.colors.cityLights.toString();
const hoverBackgroundColor = styleController.guide.colors.henra
    .lighten(0.9)
    .desaturate(0.5)
    .toString();
const transition = `background-color ${styleController.guide.animationDurations.effect}ms linear`;
export const classNames = {
    container: [
        styleController.create({
            backgroundColor: 'white',
            cursor: 'pointer',
            transition,
            $nest: {
                '&:hover': {
                    backgroundColor: hoverBackgroundColor,
                    transitionDuration: '0s',
                },
                '&:not(:last-child)': {
                    borderBottom: `1px solid ${separatorColor}`,
                },
            },
        }),
        mixins.column(2),
        mixins.row(1, true),
    ].join(' '),
    icon: styleController.create({
        marginRight: styleController.guide.spacing.horizontal,
    }),
};
