var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var UserCreationScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Icon } from '@ui-components/elements/Icon';
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
import { SearchInput } from '@ui-components/elements/SearchInput';
import { dealersStore, usersStore } from 'henra-store';
import get from 'lodash-es/get';
import { observer } from 'mobx-react';
import React from 'react';
import { boundMethod } from 'utilities';
import { classNames } from './userCreation.style';
let UserCreationScreen = UserCreationScreen_1 = class UserCreationScreen extends React.Component {
    constructor() {
        super(...arguments);
        this.searchInputRef = React.createRef();
        this.state = {
            searchResults: [],
            selectedDealer: null,
        };
    }
    render() {
        const { searchResults } = this.state;
        const searchInputValue = get(this.state, ['selectedDealer', 'store']);
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: usersStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.goBack },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            "gebruikers")),
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement(ModelEditor, { title: "Nieuwe gebruiker aanmaken", data: UserCreationScreen_1.defaultValues, appendChildren: React.createElement(React.Fragment, null,
                                    React.createElement(SearchInput, { ref: this.searchInputRef, onSubmit: this.searchDealers, data: searchResults, useAsKey: '_id', useResultKey: 'store', useResultIcon: 'user-tie', onResultPress: this.handleResultPress, inputProps: {
                                            label: 'Wijs een dealer toe',
                                        }, value: searchInputValue })), include: this.getIncludes(UserCreationScreen_1.includeUserKeys, UserCreationScreen_1.includeUserLabels), onSubmit: this.handleSubmit })))))));
    }
    handleSubmit(event, data) {
        usersStore
            .createUser(Object.assign(Object.assign({}, data), { role: 'dealer', search: null, dealer: get(this.state.selectedDealer, ['id']) }))
            .then(() => {
            this.goBack();
        });
    }
    handleResultPress(item) {
        this.searchInputRef.current.inputRef.current.inputRef.current.value =
            item.store;
        this.setState({
            selectedDealer: item,
        });
    }
    searchDealers(value) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const results = yield dealersStore.searchDealers(value, false);
                this.setState({
                    searchResults: results,
                });
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    goBack() {
        this.props.history.push('/users');
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            return {
                key: field,
                inputProps: {
                    label: labels[index],
                    required: field === 'email' || field === 'password',
                },
            };
        });
    }
};
UserCreationScreen.includeUserKeys = [
    'firstName',
    'lastName',
    'email',
    'password',
];
UserCreationScreen.includeUserLabels = [
    'voornaam',
    'achternaam',
    'e-mailadres',
    'wachtwoord',
];
UserCreationScreen.defaultValues = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    role: 'dealer',
};
__decorate([
    boundMethod
], UserCreationScreen.prototype, "handleSubmit", null);
__decorate([
    boundMethod
], UserCreationScreen.prototype, "handleResultPress", null);
__decorate([
    boundMethod
], UserCreationScreen.prototype, "searchDealers", null);
__decorate([
    boundMethod
], UserCreationScreen.prototype, "goBack", null);
UserCreationScreen = UserCreationScreen_1 = __decorate([
    observer
], UserCreationScreen);
export { UserCreationScreen };
