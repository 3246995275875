export var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["INQUIRY"] = 0] = "INQUIRY";
    OrderStatus[OrderStatus["QUOTATION"] = 1] = "QUOTATION";
    OrderStatus[OrderStatus["ORDER"] = 2] = "ORDER";
    OrderStatus[OrderStatus["COMPLETED"] = 3] = "COMPLETED";
    OrderStatus[OrderStatus["CANCELED"] = 4] = "CANCELED";
})(OrderStatus || (OrderStatus = {}));
export { ordersStore } from './orders.store';
export { ordersService } from './orders.service';
