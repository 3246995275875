var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { assetsStore } from 'henra-store/assets/assets.store';
// types
import { XHRState } from 'henra-store/services/XHRState';
import { action, observable } from 'mobx';
import { boundMethod } from 'utilities';
// service
import { usersService } from './users.service';
class UsersStore {
    constructor() {
        this.users = null;
        this.userToEdit = null;
        this.me = null;
    }
    fetchMe() {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.fetchMe();
                this.me = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    updateMe(body, assetFormData) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                let _assetResponse;
                if (assetFormData) {
                    _assetResponse = yield assetsStore.uploadAsset(assetFormData);
                }
                const response = yield usersService.updateUser(Object.assign({ _id: this.me._id, image: _assetResponse
                        ? _assetResponse._id
                        : null }, body));
                this.me = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    fetchUser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.userToEdit = null;
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.fetchUser(id);
                this.userToEdit = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchUsers(filters, set = true) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.fetchUsers();
                if (set)
                    this.users = response;
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    updateUser(body) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.updateUser(body);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    requestPasswordReset(email) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.requestPasswordReset(email);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    resetPassword(email, token, password) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.resetPassword(email, token, password);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    createUser(body) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.createUser(body);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (_a) {
                this.state = XHRState.Rejected;
                return Promise.reject();
            }
        });
    }
    deleteUser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state = XHRState.Pending;
            try {
                const response = yield usersService.deleteUser(id);
                this.state = XHRState.Fulfilled;
                return response;
            }
            catch (e) {
                this.state = XHRState.Rejected;
                return Promise.reject(e);
            }
        });
    }
    sortUsers(by, direction) {
        this.users = this.users.slice().sort(function (a, b) {
            if (a[by] < b[by])
                return -1 * direction;
            if (a[by] > b[by])
                return 1 * direction;
            return 0;
        });
    }
}
__decorate([
    observable
], UsersStore.prototype, "state", void 0);
__decorate([
    observable
], UsersStore.prototype, "users", void 0);
__decorate([
    observable
], UsersStore.prototype, "userToEdit", void 0);
__decorate([
    observable
], UsersStore.prototype, "me", void 0);
__decorate([
    action
], UsersStore.prototype, "fetchMe", null);
__decorate([
    boundMethod,
    action
], UsersStore.prototype, "updateMe", null);
__decorate([
    action
], UsersStore.prototype, "fetchUser", null);
__decorate([
    action
], UsersStore.prototype, "fetchUsers", null);
__decorate([
    action
], UsersStore.prototype, "updateUser", null);
__decorate([
    action
], UsersStore.prototype, "requestPasswordReset", null);
__decorate([
    action
], UsersStore.prototype, "resetPassword", null);
__decorate([
    action
], UsersStore.prototype, "createUser", null);
__decorate([
    boundMethod,
    action
], UsersStore.prototype, "sortUsers", null);
export const usersStore = new UsersStore();
