import { styleController } from 'style';
export const classNames = {
    container: styleController.create({
        width: '100%',
        height: '100%',
        position: 'relative',
    }),
    activityIndicator: styleController.create({
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    }),
};
