var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import React from 'react';
import upperFirst from 'lodash-es/upperFirst';
import get from 'lodash-es/get';
// utils
import { boundMethod, isDefined } from 'utilities';
// styles
import { classNames } from './Input.style';
import { Icon } from '../Icon';
export class Input extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            valid: false,
            focused: false,
            changed: false,
        };
        this.inputRef = React.createRef();
    }
    render() {
        const { description, htmlAttributes, icon, label } = this.props;
        const containerClassNames = this.createContainerClassNames();
        const inputClassNames = this.createInputClassNames();
        const descriptionClassNames = this.createDescriptionClassNames();
        return (React.createElement("div", { className: containerClassNames },
            label && (React.createElement("label", { className: classNames.label, htmlFor: get(htmlAttributes, 'id') }, this.createLabelText())),
            React.createElement("div", { className: classNames.inputContainer },
                icon && React.createElement(Icon, { icon: icon, className: classNames.icon }),
                React.createElement("input", Object.assign({}, htmlAttributes, { className: inputClassNames, onFocus: this.handleFocus, onChange: this.handleChange, onBlur: this.handleBlur, ref: this.inputRef }))),
            this.showDescription() && (React.createElement("div", { className: descriptionClassNames }, description))));
    }
    showDescription() {
        const { description, onlyShowDescriptionWhenInvalid } = this.props;
        const { valid, changed } = this.state;
        if (!description)
            return false;
        if (!onlyShowDescriptionWhenInvalid)
            return true;
        if (onlyShowDescriptionWhenInvalid && changed && !valid)
            return true;
        return false;
    }
    createLabelText() {
        const { label, required, showRequirement } = this.props;
        const suffix = showRequirement
            ? required
                ? Input.requiredSuffix
                : Input.optionalSuffix
            : '';
        return `${upperFirst(label)} ${suffix}`;
    }
    createDescriptionClassNames() {
        const { onlyShowDescriptionWhenInvalid } = this.props;
        const descriptionClassNames = [
            classNames.description,
            onlyShowDescriptionWhenInvalid ? 'warning' : null,
        ].join(' ');
        return descriptionClassNames;
    }
    createContainerClassNames() {
        const classNameProp = this.props.className || '';
        const containerClassNames = [classNames.container, classNameProp]
            .join(' ')
            .trim();
        return containerClassNames;
    }
    createInputClassNames() {
        const shouldValidate = this.props.validation ? true : false;
        const { changed, focused, valid } = this.state;
        const useIconSpacing = isDefined(this.props.icon);
        const inputClassNames = [
            classNames.input,
            useIconSpacing ? 'with-icon' : null,
            shouldValidate
                ? focused
                    ? valid
                        ? 'valid'
                        : changed
                            ? 'invalid'
                            : null
                    : null
                : null,
        ]
            .join(' ')
            .trim();
        return inputClassNames;
    }
    handleFocus(event) {
        const { htmlAttributes } = this.props;
        const onFocusProps = get(htmlAttributes, 'onFocus');
        if (onFocusProps)
            onFocusProps(event);
        this.setState({
            focused: true,
        });
    }
    handleBlur(event) {
        const { htmlAttributes } = this.props;
        const onBlurProps = get(htmlAttributes, 'onBlur');
        if (onBlurProps)
            onBlurProps(event);
        this.setState({
            focused: false,
            changed: event.target.value.length > 0 ? true : false,
        });
    }
    handleChange(event) {
        const { htmlAttributes, validation } = this.props;
        const onChangeProp = get(htmlAttributes, 'onChange');
        const valid = validation ? validation(event.target.value) : true;
        if (onChangeProp)
            onChangeProp(event);
        this.setState({
            valid,
        });
    }
}
Input.defaultProps = {
    showRequirement: true,
};
Input.requiredSuffix = '*';
Input.optionalSuffix = '(optioneel)';
__decorate([
    boundMethod
], Input.prototype, "handleFocus", null);
__decorate([
    boundMethod
], Input.prototype, "handleBlur", null);
__decorate([
    boundMethod
], Input.prototype, "handleChange", null);
