var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from 'henra-store/services/http.service';
export const dealersService = new (class DealersService extends HttpService {
    fetchDealers(urlParams) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/dealers' + (urlParams || ''));
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    fetchDealer(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/dealers/' + id);
            }
            catch (e) {
                return Promise.reject();
            }
        });
    }
    updateDealer(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.putRequest('/dealers', data);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    createDealer(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.postRequest('/dealers', data);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    removeDealer(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.deleteRequest('/dealers/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
})();
