var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// modules
import axios from 'axios';
// services
import { authenticationService } from 'henra-store/authentication/authentication.service';
export class HttpService {
    constructor() {
        this.interface = axios.create();
        this.interface.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            // handle unauthorized
            if (error.response.status === 401) {
                console.warn('Unauthorized request!');
                localStorage.clear();
                location.reload();
            }
            return Promise.reject(error);
        });
    }
    /**
     * Executes an http request
     * @param method
     * @param query
     * @param data
     */
    send(method, query, data = {}, options = {}, config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            query += this.convertOptions(options);
            const response = yield this.interface(Object.assign({ headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: authenticationService.getSessionToken(),
                }, timeout: 60000, method: method, url: query, data: data, baseURL: HttpService.baseURL }, config));
            if (process.env.NODE_ENV === 'development') {
                console.log('XHTTPRequest', { query, response });
            }
            return response.data;
        });
    }
    /**
     * Executes a GET request
     * @param query
     */
    getRequest(query, options = {}, config = {}) {
        return this.send('GET', query, null, options, config);
    }
    /**
     * Executes a POST request
     * @param query
     * @param body
     */
    postRequest(query, body, options = {}) {
        return this.send('POST', query, body, options);
    }
    /**
     * Executes a PUT request
     * @param query
     * @param body
     */
    putRequest(query, body, options = {}) {
        return this.send('PUT', query, body, options);
    }
    /**
     * Executes a DELETE request
     * @param query
     */
    deleteRequest(query, options = {}) {
        return this.send('DELETE', query, null, options);
    }
    /**
     * Convert options to query params
     * @param options
     */
    convertOptions(options) {
        const params = [];
        for (const option of Object.keys(options)) {
            params.push([
                option,
                Array.isArray(options[option])
                    ? options[option].join(',')
                    : options[option],
            ].join('='));
        }
        return params.length ? `?${params.join('&')}` : '';
    }
}
HttpService.baseURL = process.env.API_URL;
