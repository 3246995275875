import { mixins, styleController } from 'style';
const { spacing } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({}),
        mixins.row(1, true),
        mixins.column(),
    ].join(' '),
    title: [
        styleController.create({
            borderBottom: `1px solid ${styleController.guide.colors.henra}`,
        }),
        mixins.row(1, true),
    ].join(' '),
    form: styleController.create({
        $nest: {
            '& > div': {
                margin: `${spacing.vertical * 2}px 0`,
                $nest: {
                    '& input': { width: '100%' },
                },
            },
        },
    }),
    advanced: [mixins.row(), mixins.column()].join(' '),
};
