import React from 'react';
export class List extends React.Component {
    render() {
        return this.createList();
    }
    createList() {
        const { items, template, useKey } = this.props;
        const nodes = [];
        for (let i = 0, l = items.length; i < l; i++) {
            const item = items[i];
            const node = template(item, i, useKey || i);
            nodes.push(node);
        }
        return nodes;
    }
}
