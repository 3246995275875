var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var LoginScreen_1;
// components
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Form } from '@ui-components/elements/Form';
import { Input } from '@ui-components/elements/Input';
// store
import { authenticationStore } from 'henra-store';
import upperFirst from 'lodash-es/upperFirst';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { boundMethod } from 'utilities';
// styles
import { classNames } from './login.style';
let LoginScreen = LoginScreen_1 = class LoginScreen extends React.Component {
    render() {
        const buttonChildNodes = this.createButtonChildNodes();
        return (React.createElement("div", { className: classNames.container },
            React.createElement("div", { className: classNames.formContainer },
                React.createElement(ActivityContainer, { state: authenticationStore.state },
                    React.createElement(Form, Object.assign({}, LoginScreen_1.formProps, { onSubmit: this.handleFormSubmit }),
                        React.createElement(Input, Object.assign({}, LoginScreen_1.emailInputProps)),
                        React.createElement(Input, Object.assign({}, LoginScreen_1.passwordInputProps)),
                        React.createElement(Button, Object.assign({}, LoginScreen_1.buttonProps), buttonChildNodes)),
                    React.createElement(Link, { className: classNames.anchor, to: "/reset-password" }, "Wachtwoord vergeten")))));
    }
    createButtonChildNodes() {
        return upperFirst('log in');
    }
    handleSuccessfulLogin() {
        this.props.history.push('/');
    }
    handleRejectedLogin(error) { }
    handleFormSubmit(event, credentials) {
        if (event.currentTarget.reportValidity()) {
            authenticationStore
                .login(credentials)
                .then(this.handleSuccessfulLogin.bind(this))
                .catch(this.handleRejectedLogin);
        }
    }
};
LoginScreen.emailInputProps = {
    label: 'e-mailadres',
    required: true,
    className: classNames.input,
    htmlAttributes: {
        type: 'email',
        name: 'email',
        required: true,
    },
};
LoginScreen.passwordInputProps = {
    label: 'wachtwoord',
    required: true,
    className: classNames.input,
    htmlAttributes: {
        type: 'password',
        name: 'password',
        required: true,
    },
};
LoginScreen.buttonProps = {
    htmlAttributes: { className: classNames.button, type: 'submit' },
};
LoginScreen.formProps = {
    submitOnEnter: true,
};
__decorate([
    boundMethod
], LoginScreen.prototype, "handleFormSubmit", null);
LoginScreen = LoginScreen_1 = __decorate([
    observer
], LoginScreen);
export { LoginScreen };
