var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Icon } from '@ui-components/elements/Icon';
// components
import { List } from '@ui-components/elements/List';
import { ScrollView } from '@ui-components/elements/ScrollView';
import get from 'lodash-es/get';
import React from 'react';
// utils
import { boundMethod, isDefined } from 'utilities';
import { Button } from '../Button';
import { Select } from '../Select';
// styles
import { classNames } from './table.style';
export class Table extends React.Component {
    constructor(props) {
        super(props);
        this.scrollViewRef = React.createRef();
        this.paginationAmounts = [20, 50, 100];
        this.state = {
            showTable: false,
            scrollWidth: 0,
            sortBy: this.props.defaultSortBy ||
                this.props.include[0],
            sortingDirection: 1,
            limit: this.paginationAmounts[0],
            offset: 0,
        };
        this.createTableHeadNodes = (item, index) => {
            const { sortBy, sortingDirection } = this.state;
            const property = this.props.include[index];
            const isSorted = sortBy === property;
            return (React.createElement("th", { key: index, className: [
                    classNames.tableCell,
                    classNames.tableHead,
                    isSorted ? 'sorted' : '',
                ].join(' '), onClick: this.handleTableHeadClick, "data-type": property },
                item,
                React.createElement("div", { className: classNames.sortingIcons }, isSorted ? (sortingDirection === 1 ? (React.createElement(Icon, { icon: "sort-up", className: [classNames.sortingIcon, classNames.activeIcon].join(' ') })) : (React.createElement(Icon, { icon: "sort-down", className: [classNames.sortingIcon, classNames.activeIcon].join(' ') }))) : (React.createElement(Icon, { icon: "sort", className: classNames.sortingIcon })))));
        };
        const { headers, include } = this.props;
        if (headers.length !== include.length) {
            throw new Error('`include` and `headers` are not equal size. There should be a header for each included field.');
        }
    }
    render() {
        const data = this.props.data || [];
        const { limit, offset } = this.state;
        return (React.createElement("div", { className: classNames.tableContainer },
            React.createElement("div", { className: classNames.topTools },
                React.createElement("div", { className: classNames.resultRange }, `${offset} - ${offset + limit}`),
                React.createElement(Select, { icon: "bars", options: this.paginationAmounts.map(this.createOption), htmlAttributes: {
                        onChange: this.handlePaginationChange,
                    } }),
                React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { className: classNames.navButton }, onPress: this.decreaseOffset }, "vorige"),
                React.createElement(Button, { design: 1 /* Ghost */, htmlAttributes: { className: classNames.navButton }, onPress: this.increaseOffset }, "volgende")),
            this.createTableNode(data, this.props.useKey),
            React.createElement("div", { className: classNames.bottomTools }, "tools")));
    }
    increaseOffset() {
        const { data } = this.props;
        const { limit, offset } = this.state;
        if (isDefined(data) && data.length < limit)
            return;
        const nextState = offset + limit;
        this.setState({
            offset: nextState,
        }, this.props.onPaginationChange.bind(this, {
            limit: this.state.limit,
            offset: nextState,
        }));
    }
    decreaseOffset() {
        const { limit, offset } = this.state;
        if (offset === 0)
            return;
        const nextLimit = offset - limit;
        const nextState = offset > 0 ? (nextLimit <= 0 ? 0 : nextLimit) : 0;
        this.setState({
            offset: nextState,
        }, this.props.onPaginationChange.bind(this, {
            limit: this.state.limit,
            offset: nextState,
        }));
    }
    handlePaginationChange(event) {
        const $target = event.target;
        const callback = this.props.onPaginationChange;
        const limit = parseInt($target.value);
        this.setState({
            limit: limit,
        });
        if (callback)
            callback({ limit: limit, offset: this.state.offset });
    }
    createOption(value) {
        const option = {
            label: value.toString(),
            value: value.toString(),
        };
        return option;
    }
    componentDidMount() {
        this.setViewScrollDimensions();
        window.addEventListener('resize', this.setViewScrollDimensions);
    }
    componentDidUpdate() {
        this.setViewScrollDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setViewScrollDimensions);
    }
    setViewScrollDimensions() {
        const $container = this.scrollViewRef.current.containerRef.current
            .parentElement;
        // wait for repaint after vDOM reconciliation from react
        requestAnimationFrame(() => {
            const style = getComputedStyle($container);
            const width = parseInt(style.width) -
                parseInt(style.paddingLeft) -
                parseInt(style.paddingRight);
            if (this.state.scrollWidth !== width) {
                this.setState({
                    scrollWidth: width,
                    showTable: true,
                });
            }
        });
    }
    createTableNode(data, nodeKey) {
        const tableClassNames = this.createTableClassNames(this.props.className);
        return (React.createElement(ScrollView, { height: '100%', width: this.state.scrollWidth, ref: this.scrollViewRef }, this.state.showTable && (React.createElement("table", { className: tableClassNames },
            React.createElement("thead", null,
                React.createElement("tr", { className: classNames.tableRow },
                    React.createElement(List, { items: this.props.headers, template: this.createTableHeadNodes }))),
            React.createElement("tbody", null,
                React.createElement(List, { items: data, template: this.createRowNode, useKey: nodeKey }))))));
    }
    createRowNode(item, index, key) {
        const _key = item[key].toString();
        const HTMLAttributeProp = this.props.rowHTMLAttributes;
        const HTMLAttributes = isDefined(HTMLAttributeProp)
            ? typeof HTMLAttributeProp === 'function'
                ? HTMLAttributeProp(item)
                : HTMLAttributeProp
            : {};
        return (React.createElement("tr", Object.assign({ key: _key, className: classNames.tableRow }, HTMLAttributes),
            React.createElement(List, { items: this.props.include, template: this.createFieldNode.bind(null, item) })));
    }
    createFieldNode(item, fieldName, index) {
        let data;
        const proc = get(this.props, ['includeProcessors', index]);
        if (fieldName.indexOf('.') > -1) {
            const path = fieldName.split('.');
            const value = get(item, path);
            data = proc ? proc(value, item) : value || '-';
        }
        else {
            data = proc ? proc(item[fieldName], item) : item[fieldName];
        }
        data = data || '-';
        try {
            const value = data.toString();
            return (React.createElement("td", { key: fieldName, className: [classNames.tableCell, classNames.tableData].join(' ') }, value));
        }
        catch (e) {
            console.error('Object property needs to have a toString function to be able to render.');
        }
    }
    createTableClassNames(...defaults) {
        return [...defaults, classNames.table].join(' ');
    }
    handleTableHeadClick(event) {
        const $target = event.target;
        const value = $target.dataset['type'];
        let newDirection;
        this.setState(state => {
            newDirection =
                state.sortBy === value
                    ? state.sortingDirection === 1
                        ? -1
                        : 1
                    : state.sortingDirection;
            return {
                sortBy: value,
                sortingDirection: newDirection,
            };
        }, () => {
            if (this.props.sort)
                this.props.sort(value, newDirection);
        });
    }
}
__decorate([
    boundMethod
], Table.prototype, "increaseOffset", null);
__decorate([
    boundMethod
], Table.prototype, "decreaseOffset", null);
__decorate([
    boundMethod
], Table.prototype, "handlePaginationChange", null);
__decorate([
    boundMethod
], Table.prototype, "setViewScrollDimensions", null);
__decorate([
    boundMethod
], Table.prototype, "createRowNode", null);
__decorate([
    boundMethod
], Table.prototype, "createFieldNode", null);
__decorate([
    boundMethod
], Table.prototype, "handleTableHeadClick", null);
