import { List } from '@ui-components/elements/List';
// components
import { PrivateRoute } from '@ui-components/elements/PrivateRoute';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
export class Router extends React.Component {
    render() {
        const routeNodes = this.createRouteNodes(this.props);
        return React.createElement(BrowserRouter, null, routeNodes);
    }
    createRouteNodes(props) {
        const Container = props.containerComponent || React.Fragment;
        return (React.createElement(Container, Object.assign({}, props.containerProps),
            props.before,
            React.createElement(Switch, null,
                React.createElement(List, { items: props.routes, template: this.createRouteTemplate })),
            props.after));
    }
    createRouteTemplate(item, index) {
        if (item.private)
            return React.createElement(PrivateRoute, Object.assign({ key: index }, item));
        return React.createElement(Route, Object.assign({ key: index }, item));
    }
}
