import { styleController } from './styleController';
function createRow(spacingFactor = 1, usePadding = false) {
    const verticalSpacing = styleController.guide.spacing.vertical * spacingFactor;
    return styleController.create({
        [usePadding ? 'paddingTop' : 'marginTop']: `${verticalSpacing}px`,
        [usePadding ? 'paddingBottom' : 'marginBottom']: `${verticalSpacing}px`,
    });
}
function createColumn(spacingFactor = 1, useMargin = false) {
    const horizontalSpacing = styleController.guide.spacing.horizontal * spacingFactor;
    return styleController.create({
        [useMargin ? 'marginLeft' : 'paddingLeft']: `${horizontalSpacing}px`,
        [useMargin ? 'marginRight' : 'paddingRight']: `${horizontalSpacing}px`,
    });
}
export const mixins = {
    row: createRow,
    column: createColumn,
};
