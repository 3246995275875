var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import _get from 'lodash-es/get';
// components
import { Router } from '@ui-components/elements/Router';
import { MainNavigationMenu } from '@ui-components/elements/MainNavigationMenu';
// routes
import { routes } from 'routes';
// store
import { authenticationStore, usersStore } from 'henra-store';
let Main = class Main extends React.Component {
    constructor() {
        super(...arguments);
        this.routerContainerComponent = 'div';
        this.routerContainerProps = {
            style: { display: 'flex', height: '100%' },
        };
    }
    render() {
        const beforeNode = this.createBeforeNode();
        const afterNode = this.createAfterNode();
        const routesForRole = routes(_get(usersStore, ['me', 'role']) || 'user');
        return (React.createElement(Router, { before: beforeNode, after: afterNode, routes: routesForRole, containerComponent: this.routerContainerComponent, containerProps: this.routerContainerProps }));
    }
    createBeforeNode() {
        const shouldRenderNavigationmenu = authenticationStore.authenticated === true;
        return shouldRenderNavigationmenu ? React.createElement(MainNavigationMenu, null) : null;
    }
    createAfterNode() {
        return null;
    }
};
Main = __decorate([
    observer
], Main);
export { Main };
