var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// libs
import React from 'react';
// utils
import { boundMethod } from 'utilities';
// style
import { classNames } from './button.style';
export class Button extends React.Component {
    constructor() {
        super(...arguments);
        this.buttonElementRef = React.createRef();
    }
    render() {
        const buttonClassNames = this.createButtonClassNames();
        const { children, htmlAttributes } = this.props;
        return (React.createElement("button", Object.assign({}, htmlAttributes, { className: buttonClassNames, ref: this.buttonElementRef, onClick: this.handleButtonPress }), children));
    }
    createButtonClassNames() {
        const { design, htmlAttributes } = this.props;
        let designClass;
        switch (design) {
            case 0 /* CTA */:
                designClass = classNames.cta;
                break;
            case 1 /* Ghost */:
                designClass = classNames.ghost;
                break;
        }
        const buttonClassNames = [
            classNames.button,
            designClass,
            htmlAttributes.className,
        ]
            .join(' ')
            .trim();
        return buttonClassNames;
    }
    handleButtonPress(event) {
        const callback = this.props.onPress;
        if (callback)
            callback(event);
    }
}
Button.defaultProps = {
    htmlAttributes: {},
    design: 0 /* CTA */,
};
__decorate([
    boundMethod
], Button.prototype, "handleButtonPress", null);
