var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var InquiriesScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { DataManager } from '@ui-components/elements/DataManager';
import { Page } from '@ui-components/elements/Page';
import { ordersStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
let InquiriesScreen = InquiriesScreen_1 = class InquiriesScreen extends React.Component {
    constructor() {
        super(...arguments);
        this.includeProcessors = this.createKeyProcessors();
    }
    createKeyProcessors() {
        const procs = new Array(InquiriesScreen_1.includeKeys.length);
        procs[0] = function (date) {
            return date.split('T')[0];
        };
        procs[2] = ordersStore.statusToString;
        return procs;
    }
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: ordersStore.state },
                React.createElement(DataManager, Object.assign({}, this.props, { create: false, data: ordersStore.inquiries, dataIdentifierKey: "_id", includeKeys: InquiriesScreen_1.includeKeys, includeProcessors: this.includeProcessors, keyLabels: InquiriesScreen_1.keyHeaders, state: ordersStore.state, subjectName: "aanvragen", subjectNamePlural: "aanvragen", title: "Aanvragen", url: "inquiries", fetchHandler: this.fetchInquiries, defaultSortBy: '-createdAt' })))));
    }
    fetchInquiries(params) {
        ordersStore.fetchInquiries(params);
    }
};
InquiriesScreen.includeKeys = [
    'createdAt',
    'modelProduct.name',
    'status',
    'clientName',
    'clientEmail',
    'clientPhone',
    'clientAddress',
    'clientCity',
    'clientCountry',
];
InquiriesScreen.keyHeaders = [
    'datum',
    'product',
    'status',
    'naam klant',
    'e-mail klant',
    'telefoon klant',
    'adres klant',
    'plaats klant',
    'land klant',
];
InquiriesScreen = InquiriesScreen_1 = __decorate([
    observer
], InquiriesScreen);
export { InquiriesScreen };
