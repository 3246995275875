import { styleController, mixins } from 'style';
const { colors, animationDurations, spacing, borderRadii, } = styleController.guide;
const cellBorder = `1px solid ${colors.soothingBreeze}`;
export const classNames = {
    tableContainer: styleController.create({
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        border: cellBorder,
        borderRadius: borderRadii[1],
    }),
    topTools: [
        styleController.create({
            display: 'flex',
            alignItems: 'center',
        }),
        mixins.column(),
        mixins.row(1, true),
    ].join(' '),
    resultRange: [styleController.create({}), mixins.column()].join(' '),
    navButton: styleController.create({ marginLeft: spacing.horizontal }),
    bottomTools: styleController.create({
        borderTop: cellBorder,
        // TO-DO kleur weghalen
        color: 'white',
    }),
    table: styleController.create({
        width: 'max-content',
        position: 'relative',
        borderSpacing: 0,
        flex: 1,
    }),
    tableRow: styleController.create({
        transition: `background-color ${animationDurations.effect}ms ease-out`,
        cursor: 'pointer',
        $nest: {
            '&:hover': {
                transitionDuration: '0',
                backgroundColor: colors.cityLights.toString(),
            },
            '&:first-child': {
            // background: "red",
            },
        },
    }),
    tableCell: [
        styleController.create({
            textAlign: 'left',
            borderRight: cellBorder,
            borderBottom: cellBorder,
            $nest: {
                '&:last-child': {
                    borderRight: 'none',
                },
            },
        }),
        mixins.column(2),
        mixins.row(1, true),
    ].join(' '),
    tableData: styleController.create({}),
    tableHead: styleController.create({
        background: 'white',
        borderBottom: `3px solid ${colors.soothingBreeze}`,
        borderTop: cellBorder,
        position: ['sticky', '-webkit-sticky'],
        top: 0,
        resize: 'horizontal',
        overflow: 'auto',
        userSelect: 'none',
        $nest: {
            '& > *': {
                pointerEvents: 'none',
            },
        },
    }),
    sortingIcons: styleController.create({
        display: 'inline-block',
        marginLeft: spacing.horizontal,
    }),
    sortingIcon: styleController.create({
        opacity: 0.3,
    }),
    activeIcon: styleController.create({
        color: colors.henra.toString(),
        opacity: '1 !important',
    }),
};
