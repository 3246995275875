import { styleController } from 'style';
const { borderRadii, fonts, fontWeights, spacing, colors, } = styleController.guide;
export const classNames = {
    container: styleController.create({
        position: 'relative',
        display: 'inline-block',
    }),
    select: styleController.create({
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        padding: `${spacing.vertical / 1.5}px ${spacing.horizontal *
            4}px ${spacing.vertical / 1.5}px ${spacing.horizontal * 2}px`,
        background: 'white',
        border: `1px solid ${colors.americanRiver}`,
        font: 'inherit',
    }),
    label: styleController.create({
        display: 'block',
        marginBottom: `${spacing.vertical * 0.5}px`,
        fontSize: '0.85rem',
        fontFamily: fonts.header,
        fontWeight: fontWeights.header[1],
        paddingLeft: `${borderRadii[0]}px`,
    }),
    icon: styleController.create({
        position: 'absolute',
        right: spacing.horizontal,
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        color: colors.text.toString(),
    }),
};
