import { styleController, mixins } from 'style';
const { fontWeights } = styleController.guide;
export const classNames = {
    title: function (size) {
        return [
            styleController.create({
                $debugName: 'title',
                fontWeight: fontWeights.header[1],
                fontSize: ['1.1rem', '1.3rem', '1.6rem'][size],
            }),
            mixins.row(),
        ].join(' ');
    },
};
