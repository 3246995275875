import { isDefined } from './isDefined';
export function encodeURLParams(object, asFilter = false, prefix = false) {
    const keys = Object.keys(object);
    if (keys.length === 0)
        return '';
    return prefix
        ? '?'
        : '' +
            keys
                .map(function (key) {
                const value = object[key];
                const _key = asFilter ? `filter[${key}]` : key;
                return `${_key}${isDefined(value) ? `=${value}` : ''}`;
            })
                .join('&');
}
