import { styleController, mixins } from 'style';
const { spacing, colors, fontWeights } = styleController.guide;
export const classNames = {
    container: [
        styleController.create({
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: spacing.vertical,
        }),
        mixins.column(),
    ].join(' '),
    scrollContainer: styleController.create({
        flex: 1,
        overflow: 'hidden',
        borderTop: `1px solid ${colors.henra}`,
        paddingTop: `${-spacing.vertical}px !important`,
    }),
    formContainer: mixins.row(2),
    top: mixins.row(1, true),
    buttonIcon: styleController.create({
        marginRight: spacing.horizontal,
    }),
    downloadButton: mixins.column(2, true),
    input: mixins.row(),
    infoTable: mixins.row(3),
    formFragment: [
        mixins.column(),
        styleController.create({
            marginTop: spacing.vertical * 2,
            marginBottom: spacing.vertical * 3,
            $nest: {
                '& input': {
                    width: '100%',
                },
            },
        }),
    ].join(' '),
    tr: [
        styleController.create({
            display: 'flex',
            $nest: {
                '&:first-child': {
                    borderBottom: `1px solid ${colors.text}`,
                },
                '&:last-child': {
                    borderTop: `1px solid ${colors.text}`,
                    fontWeight: fontWeights.body[1],
                },
            },
        }),
        mixins.row(0.5, true),
    ].join(' '),
    tc: [
        styleController.create({
            lineHeight: '1.5em',
            $nest: {
                '&:first-child': {
                    flex: 1,
                },
            },
        }),
        mixins.column(),
    ].join(' '),
    dealerSearchInput: mixins.row(),
    advanced: mixins.row(),
};
