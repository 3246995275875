var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ProductCreationScreen_1;
import { ActivityContainer } from '@ui-components/elements/ActivityContainer';
import { Button } from '@ui-components/elements/Button';
import { Icon } from '@ui-components/elements/Icon';
// components
import { ModelEditor, } from '@ui-components/elements/ModelEditor';
import { Page } from '@ui-components/elements/Page';
import { ScrollView } from '@ui-components/elements/ScrollView';
// store
import { productsStore } from 'henra-store';
import { observer } from 'mobx-react';
import React from 'react';
// utils
import { boundMethod } from 'utilities';
// styles
import { classNames } from './productCreation.style';
let ProductCreationScreen = ProductCreationScreen_1 = class ProductCreationScreen extends React.Component {
    render() {
        return (React.createElement(Page, null,
            React.createElement(ActivityContainer, { state: productsStore.state },
                React.createElement("div", { className: classNames.container },
                    React.createElement("div", { className: classNames.top },
                        React.createElement(Button, { design: 1 /* Ghost */, onPress: this.goBack },
                            React.createElement(Icon, { icon: "arrow-left", className: classNames.buttonIcon }),
                            "producten")),
                    React.createElement("div", { className: classNames.formContainer },
                        React.createElement(ScrollView, { height: "100%" },
                            React.createElement(ModelEditor, { title: "Nieuw product aanmaken", data: ProductCreationScreen_1.defaultValues, include: this.getIncludes(ProductCreationScreen_1.includeProductKeys, ProductCreationScreen_1.includeProductLabels), onSubmit: this.handleSubmit })))))));
    }
    handleSubmit(event, data) {
        productsStore
            .createProduct(Object.assign(Object.assign({}, data), { price: (data.price || 0) * 100 }))
            .then(() => {
            this.goBack();
        });
    }
    goBack() {
        this.props.history.push('/products');
    }
    getIncludes(fields, labels) {
        return fields.map((field, index) => {
            return {
                key: field,
                inputProps: {
                    label: labels[index],
                    required: true,
                    htmlAttributes: {
                        type: field === 'price' ? 'number' : 'text',
                        step: field === 'price' ? '0.01' : undefined,
                    },
                },
            };
        });
    }
};
ProductCreationScreen.includeProductKeys = [
    'name',
    'dimensions',
    'price',
    'description',
    'productId',
];
ProductCreationScreen.includeProductLabels = [
    'productnaam (wagen)',
    'afmeting',
    "prijs in euro's",
    'beschrijving',
    'artikelnummer',
];
ProductCreationScreen.defaultValues = {
    name: '',
    dimensions: '',
    price: 0,
    description: '',
    productId: '',
};
__decorate([
    boundMethod
], ProductCreationScreen.prototype, "handleSubmit", null);
__decorate([
    boundMethod
], ProductCreationScreen.prototype, "goBack", null);
ProductCreationScreen = ProductCreationScreen_1 = __decorate([
    observer
], ProductCreationScreen);
export { ProductCreationScreen };
