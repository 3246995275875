import { styleController, mixins } from 'style';
const { spacing, colors } = styleController.guide;
export const classNames = {
    filterLabel: styleController.create({
        width: '120px',
    }),
    filter: [
        styleController.create({
            display: 'flex',
            alignItems: 'center',
            $nest: {
                '& > *:not(input):not(button):not(select)': {
                    paddingLeft: spacing.horizontal,
                    paddingRight: spacing.horizontal,
                },
                '&:first-child': {
                    padding: 0,
                },
            },
        }),
        mixins.row(),
    ].join(' '),
    filters: [
        styleController.create({
            borderBottom: `1px solid ${colors.henra}`,
        }),
        mixins.row(),
    ].join(' '),
    add: styleController.create({
        display: 'flex',
        $nest: {
            '& > *:not(button)': {
                paddingLeft: spacing.horizontal,
                paddingRight: spacing.horizontal,
            },
            '& > *:first-child': {
                padding: 0,
            },
        },
    }),
    buttonIcon: styleController.create({
        marginRight: spacing.vertical,
    }),
};
