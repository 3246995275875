var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from 'henra-store/services/http.service';
export const productsService = new (class ProductsService extends HttpService {
    createProduct(body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.postRequest('/products', body);
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchProducts(urlParams) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/products' + (urlParams || ''));
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    fetchProduct(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.getRequest('/products/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
    updateProduct(body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.putRequest('/products', body);
            }
            catch (_a) {
                return Promise.reject();
            }
        });
    }
    deleteProduct(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.deleteRequest('/products/' + id);
            }
            catch (e) {
                return Promise.reject(e);
            }
        });
    }
})();
